import React, {useState} from 'react';
import classNames from 'classnames';
import {Link} from 'gatsby';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';

export default ({
					productId,
					image = {url: ''},
					title,
					cents,
					slug,
					onAdd,
					isAdding,
					ctaColor,
					ctaBackgroundColor,
					buyonamazon_link
				}) => {
	const [isAdded, setIsAdded] = useState(false);

	const onClickAdd = () => {
		onAdd().then(() => {
			setIsAdded(true);
		});
	};

	return (
		<div>
			<Link to={`/products/${slug}`} className="db link--opacity mb1" aria-label={title}>
				<div className="image size--3x4">
					<picture>
						<img
							alt={title}
							src={`${image.url}?w=500&h=666&auto=format`}
						/>
					</picture>
				</div>
			</Link>
			<div className="df fdr jcb aib mb1">
				<Link
					className="link--opacity db fw--800"
					to={`/products/${slug}`} aria-label={title}>
					{title}
				</Link>
				<Link
					className="link--underline-invert-small sans--xs"
					to={`/products/${slug}`} aria-label="Full Details">
					Full Details
				</Link>
			</div>
			<div className="mb1 product-tile__reviews">
				<div
					data-bv-show="inline_rating"
					data-bv-product-id={productId}
				/>
			</div>
		{/*	<button
				title={isAdded ? 'Added to Bag' : 'Add to Bag - ' + centsToPriceNoTrailingZeros(cents)}
				aria-label={isAdded ? 'Added to Bag' : 'Add to Bag - ' + centsToPriceNoTrailingZeros(cents)}
				onClick={onClickAdd}
				disabled={isAdding}
				className={classNames('button--pill--primary invert', {
					loading: isAdding,
				})}
				style={{
					backgroundColor: ctaBackgroundColor,
					'--borderColor': ctaBackgroundColor,
					color: ctaColor,
				}}
			>
				{isAdded ? (
					'Added'
				) : (
					<span>
						<span>{'Add'}</span>
						<span className="show--md"> to Bag</span>
						<span>
							{' - '}
							{centsToPriceNoTrailingZeros(cents)}
						</span>
					</span>
				)}
				</button> */}
					{slug &&	<a
				aria-label= {'Learn more ('+ slug.replace(/-/g, ' ')+')'} 
				href={`/products/${slug}`}
				className='button--pill--primary invert' target='_blank'>
					<span>
						<span>Learn more</span>
						<span className="show--md"></span>
					</span>
				</a>}
		</div>
	);
};
