import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import {Link} from 'gatsby';

function Patents(props) {
    if(!props.data) return ;

return props.data.length>0 && (<table className="patent-table">
    <tr>
       <th>Product Name</th>
       <th>Product SKUs</th>
       <th>U.S. Patent #</th>
    </tr>
    { props.data.map(list => (
       <tr>
          <td>
             <a href={`${list.link.url}`} className="db link--opacity mb1 link-text-underline" aria-label={list.link.title}>
                {list.link.title}
             </a>

          </td>
          <td>{list.productskus}</td>
          <td>{list.uspatent}</td>
       </tr>
    ))}
</table>);

}
export default ({
    title,
    description,
    imageDesktop,
    imageMobile,
    patentTableList=[]
}) => {

return (
	<>
	<div class="patent-image-container">
   <div class="col  c4 c6--sm c5--md patent-image-text"> <div class="rich-text--hero tc serif--sm serif--xl--sm serif--xxl--lg"><BlockContent blocks={title} serializers={richText} /></div></div>
	<picture>
            <source
                srcSet={`${imageDesktop.url}?auto=format&q=75`}
                media="(min-width: 1000px)"
            />
            
            <img
                alt={imageMobile.alt}
                src={`${imageMobile.url}?w=800&auto=format&q=75`}
            />
        </picture>
    </div>
	
	
	
<div className="grid-container contained "> 


<div className="col  c10--lg ml10--lg">

   
  
    <div className="mt3 mb3 patentdesc">
        <BlockContent blocks={description} serializers={richText} />
    </div>
    
    {patentTableList && <Patents data={patentTableList}/>}



</div></div></>
);
}