import React from 'react';
import Link from './Link';

export default ({
                    ourBrandProductName,
                    otherBrandProductName,
                    cta,
                    points = [],

                }) => (
    <div>
        <h3 class="detailed">Detailed Comparison</h3>
        <table class="detaile-table"  role="presentation">
            <tbody>
            <tr class="title">
                <td>
                    {ourBrandProductName && (
                        <p>
                            {ourBrandProductName}
                        </p>
                    )}
                </td>
                <td class="challenger">
                    {otherBrandProductName && (
                        <p>
                            {otherBrandProductName}
                        </p>
                    )}
                </td>
            </tr>

            {points.map(feature => (
                <tr>
                    <td className={feature.ourpointcolor}>
                        <div className="tc rich-text line-break">
                            {feature.ourpoint}

                        </div>
                    </td>
                    <td className={'challenger ' + feature.otherpointcolor}>
                        <div className="tc rich-text line-break">
                            {feature.otherpoint}

                        </div>
                    </td>
                </tr>
            ))}

            <tr class="buy-links">
                <td class="sundays">
                    {cta && (
                        <div className="mt3 tc">
                            <Link {...cta} className="green button" aria-label="Purchase product" />
                        </div>
                    )}
                </td>
                <td class="challenger"></td>
            </tr>
            </tbody>
        </table>
    </div>
);
