import React from 'react';
import QuoteOpen from './QuoteOpen';
import QuoteClose from './QuoteClose';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Flickity from './Flickity';

const SLIDE_OPTIONS = {
	cellAlign: 'center',
	wrapAround: true,
	pageDots: true,
	prevNextButtons: false,
	selectedAttraction: 0.1,
	friction: 0.8,
	adaptiveHeight: true,
};

export default ({
	backgroundColor = '#659092',
	foregroundColor = '#B8E8BA',
	list = [],
}) => {
	const slides = list.map(testimonial => (
		<div key={testimonial._key} className="db x pr">
			<div className="row align--center">
				<div className="col c6--lg">
					<div className="tc">
						{testimonial.image && (
							<div className="mxa mb5">
								<picture>
									<img
										alt={testimonial.image.alt}
										src={testimonial.image.url}
									/>
								</picture>
							</div>
						)}
						<h2 className="sans--lg sans--xxl--lg fw--800 mb3 mb5--lg">
							{testimonial.title}
						</h2>
						<div className="rich-text line-break">
							<BlockContent
								blocks={testimonial.description}
								serializers={richText}
							/>
						</div>
						<QuoteOpen className="db show--lg press-testimonial__quote--before" />
						<QuoteClose className="db show--lg press-testimonial__quote--after" />
					</div>
				</div>
			</div>
		</div>
	));

	return (
		<div style={{backgroundColor, color: foregroundColor}}>
			<div className="grid-container contained pt5 pb5 pt12--lg pb12--lg pr">
				{list.length > 1 ? (
					<Flickity
						options={SLIDE_OPTIONS}
						className="press-testimonial__carousel-container">
						{slides}
					</Flickity>
				) : (
					slides
				)}
				<QuoteOpen className="db hide--lg press-testimonial__quote--before" />
				<QuoteClose className="db hide--lg press-testimonial__quote--after" />
			</div>
		</div>
	);
};
