import React from 'react';
import classNames from 'classnames';
import Link from './Link';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({
	heading,
	partners=[],
	cta,
	ctaColor,
	ctaBackgroundColor,
}) => (
	<section className="stores">
		<div className="grid-container contained">
			<div className="row align--center">
				<div className="col c6--xl">
					{
						heading && (<h3 className="stores-heading serif--lg serif--xxl--lg tc">
								<BlockContent
								blocks={heading}
								serializers={richText}
							/></h3>
					)}
					<div className="flex__wrapper jcb stores-logos-wrap pl3--lg pr3--lg">
						{partners.map(partner => (
							<div className="pr3--lg pl3--lg pl1 pr1" key={partner._key} >
								<div className="tc">
									<Link
										{...partner.cta}
										aria-label={partner.image.alt}
									>
										<img alt={partner.image.alt} src={partner.image.url} />
									</Link>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
		{cta && (
			<div className="find-stores-btn tc mb6--lg mb2 ">
				<Link
					{...cta}
					style={{
						backgroundColor: ctaBackgroundColor,
						color:ctaColor
					}}
					className={classNames('button--primary mobile-view-btn find-store-btn', {
						invert: ctaBackgroundColor,
					})}
					aria-label="Find Store"
				/>
			</div>
		)}
	</section>
);
