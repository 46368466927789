import React, {Component} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Flickity from './Flickity';

const INITIAL_STATE = {
	progress: 0,
};

const SLIDE_OPTIONS = {
	cellAlign: 'left',
	freeScroll: true,
	contain: false,
	prevNextButtons: false,
	pageDots: false,
	selectedAttraction: 0.1,
	friction: 0.8,
};

export default class ImageCarousel extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};

		this.options = {
			...SLIDE_OPTIONS,
			on: {
				scroll: progress => {
					this.setProgress(progress);
				},
			},
		};
	}

	setProgress = progress => {
		this.setState({progress});
	};

	onClickPrevious = () => {
		if (!this.slider) return;

		this.slider.previous();
	};

	onClickNext = () => {
		if (!this.slider) return;

		this.slider.next();
	};

	render() {
		const {title, items = []} = this.props;

		const {progress} = this.state;

		const itemCols = items.map(
			({_key, image, title, description}, index) => (
				<div key={index} className="image-carousel__col mr2">
					<div className="image size--1x1 x mb3">
						<picture>
							<source
								srcSet={`${image.url}?w=900&auto=format&q=75`}
								media="(min-width: 1000px)"
							/>
							<source
								srcSet={`${image.url}?w=600&auto=format&q=75`}
								media="(min-width: 600px)"
							/>
							<img
								alt={image.alt}
								src={`${image.url}?w=400&auto=format`}
							/>
						</picture>
					</div>
					<div className="row">
						<div className="col c8--lg">
							<h3 className="fw--800">{title}</h3>
							<div className="rich-text line-break">
								<BlockContent
									blocks={description}
									serializers={richText}
								/>
							</div>
						</div>
					</div>
				</div>
			),
		);

		return (
			<div className="mt12 mb12 of--hidden">
				<div className="grid-container contained">
					<div className="row align--center">
						<div className="col c10--lg">
							<h2 className="serif--xxl mb5">{title}</h2>
							<div className="mb3">
								<Flickity
									sliderRef={slider => (this.slider = slider)}
									className="image-carousel"
									options={this.options}>
									{itemCols}
								</Flickity>
							</div>
							<div className="df fdr show--lg aic">
								<div className="fg0 df mr2">
									<button
										title="Previous" 
										aria-label="Previous" 
										name="previous"
										onClick={this.onClickPrevious}
										className="db mr1">
										<img
											className="db"
											alt="previous"
											src="/images/carousel-previous.svg"
										/>
									</button>
									<button
										title="Next" 
										aria-label="Next" 
										name="next"
										onClick={this.onClickNext}
										className="db">
										<img
											className="db"
											alt="next"
											src="/images/carousel-next.svg"
										/>
									</button>
								</div>
								<div className="fg1">
									<div className="of--hidden">
										<div className="image-carousel__progress-wrapper" />
										<div
											className="image-carousel__progress-bar"
											style={{
												transform: `translateX(-${
													100 - progress * 100
												}%)`,
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
