import React from 'react';
import ProductTileRelated from './ProductTileRelated';
import {connectToStore} from './Provider';
import {encode} from '../utils/shopify-gid';
import Flickity from './Flickity';
import getWholesaleVariant from '../utils/getWholesaleVariant';
import getApp from 'magic-tricks/lib/getApp';

const SLIDE_OPTIONS = {
	cellAlign: 'center',
	freeScroll: true,
	contain: true,
	prevNextButtons: false,
	pageDots: false,
	selectedAttraction: 0.1,
	friction: 0.8,
	autoPlay: 5000,
};
const SLIDE_OPTIONS_LG={...SLIDE_OPTIONS,groupCells: 3}
const ProductsCarousel = ({
	products = [],
	isAddingItem,
	addItemAndOpenCart,
	customer,
}) => {

	const inventoryProducts = products.filter(product => !product.hideLowInventoryProduct);
	const productTiles = inventoryProducts.map(product => {
		const discountVariant = getWholesaleVariant(product.variants, customer);
		const cents = discountVariant.cents;
		const variantId = discountVariant.variantId;

		const onAdd = () => {
			const app = getApp();
			const {subscription} = product;
			const customAttributes = [];
			console.log('app', app)
			if (subscription) {
				customAttributes.push({
					key: '_subscription_options',
					value: JSON.stringify(subscription),
				});
			}

			const encodedVariantId = encode('ProductVariant', variantId, {
				accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
			});

			// Track add to cart
			if (app.analytics) {
				app.analytics.addToCart({
					_productId: product.productId,
					id: discountVariant.sku,
					name: product.title,
					brand: 'Genexa',
					category: 'Medication',
					variant: discountVariant.title,
					quantity: 1,
					price: Number(discountVariant.price),
				});
			}

			return addItemAndOpenCart([
				{
					quantity: 1,
					variantId: encodedVariantId,
					customAttributes,
				},
			]);
		};

		return (
			<ProductTileRelated
				productId={product.productId}
				image={product.mainImage}
				title={product.title}
				cents={cents}
				slug={product.slug}
				isAdding={isAddingItem}
				onAdd={onAdd}
			/>
		);
	});

	return (
		<>
			{ productTiles.length &&
				(<div className="pt6 pb6 of--hidden">
					<div className="grid-container contained">
						<div className="row align--center">
							<div className="col c8--lg">

								<Flickity
									className="product-carousel show--md gutter--large--xl"
									options={SLIDE_OPTIONS_LG}>
									{productTiles.map((tile, index) => (
										<div
											key={index}
											className="product-carousel__item mr3 col c4">
											{tile}
										</div>
									))}
								</Flickity>
								<Flickity
									className="product-carousel hide--md"
									options={SLIDE_OPTIONS}>
									{productTiles.map((tile, index) => (
										<div
											key={index}
											className="product-carousel__item mr3">
											{tile}
										</div>
									))}
								</Flickity>
							</div>
						</div>
					</div>
				</div>)
			}
		</>
	);
};

const mapStateToProps = ({isAddingItem, customer}) => ({
	isAddingItem,
	customer,
});

const mapStoreToProps = ({addItemAndOpenCart}) => ({
	addItemAndOpenCart,
});

export default connectToStore(
	mapStateToProps,
	mapStoreToProps,
)(ProductsCarousel);
