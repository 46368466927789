import React from 'react';

export default (props) => (
	<svg
		{...props}
		width='49'
		height='34'
		viewBox='0 0 49 34'
		fill='none'>
		<title>Quote Open</title>
		<path
			d='M13.0057 2.31645C10.4863 2.31645 8.03713 2 5.55146 2C4.71345 2 3.87544 2 3.03743 2C2.22752 2 2.56275 3.63179 2.56275 4.21516C2.56275 6.09957 2.2463 7.94608 2.2463 9.75306C2.2463 12.7871 1.59825 16.5022 2.40453 19.4048C3.03048 21.6583 3.71033 24.9827 5.41082 26.6832C7.25791 28.5303 9.61366 28.8094 11.5816 30.34C12.5583 31.0996 13.4274 31.1246 14.4473 31.5882C15.3058 31.9784 19.4079 32.1863 19.8973 31.43C21.0784 29.6045 18.7018 27.0932 18.7018 25.3471C18.7018 24.4322 19.1184 22.333 18.315 21.6903C18.2264 21.6194 16.3897 22.2529 16.0119 22.2529C13.9798 22.2529 12.3728 21.9255 12.3728 19.7213C12.3728 18.437 12.0563 16.8941 12.0563 15.4492C12.0563 14.0706 14.0962 14.6581 15.1505 14.6581C16.6441 14.6581 17.9972 14.3416 19.4753 14.3416C22.0249 14.3416 21.2334 11.8758 21.2334 9.91129C21.2334 8.06532 21.2334 6.21935 21.2334 4.37339C21.2334 1.90695 18.7039 2.6329 16.7328 2.6329C15.7779 2.6329 12.3728 3.11897 12.3728 2'
			stroke='currentColor'
			strokeWidth='2.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M38.5252 2.31645C36.0059 2.31645 33.5567 2 31.071 2C30.233 2 29.395 2 28.557 2C27.747 2 28.0823 3.63179 28.0823 4.21516C28.0823 6.09957 27.7658 7.94608 27.7658 9.75306C27.7658 12.7871 27.1178 16.5022 27.9241 19.4048C28.55 21.6583 29.2299 24.9827 30.9303 26.6832C32.7774 28.5303 35.1332 28.8094 37.1012 30.34C38.0778 31.0996 38.9469 31.1246 39.9668 31.5882C40.8253 31.9784 44.9274 32.1863 45.4168 31.43C46.598 29.6045 44.2213 27.0932 44.2213 25.3471C44.2213 24.4322 44.6379 22.333 43.8345 21.6903C43.7459 21.6194 41.9092 22.2529 41.5315 22.2529C39.4993 22.2529 37.8923 21.9255 37.8923 19.7213C37.8923 18.437 37.5758 16.8941 37.5758 15.4492C37.5758 14.0706 39.6157 14.6581 40.67 14.6581C42.1637 14.6581 43.5167 14.3416 44.9949 14.3416C47.5444 14.3416 46.7529 11.8758 46.7529 9.91129C46.7529 8.06532 46.7529 6.21935 46.7529 4.37339C46.7529 1.90695 44.2234 2.6329 42.2523 2.6329C41.2975 2.6329 37.8923 3.11897 37.8923 2'
			stroke='currentColor'
			strokeWidth='2.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);