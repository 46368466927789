import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';

export default ({
	title,
	subtitle,
	color,
	backgroundColor,
	cta,
	ctaColor,
	ctaBackgroundColor
}) => (
	<section className="join-movement-wrap pt7--lg pb6--lg pt2 pb3" style={{
		backgroundColor: backgroundColor,
	}}>
		<div className="grid-container contained">
			<div className="row align--center">
				<div  className="col c12--xl">
					<div className="tc">
						<div>
							<h4 className="jtm-heading tc serif--lg serif--xxl--lg"
								style={{
								color: color,
							}}>
								<BlockContent
									blocks={title}
									serializers={richText}
								/>
							</h4>

						</div>
						<div className="jtm-subheading pl7--lg pr7--lg sans--md sans--lg--lg  mt3--lg mb4--lg mb1" style={{
							color: color,
						}}>
							<p>
								<BlockContent
									blocks={subtitle}
									serializers={richText}
								/>
							</p>
						</div>
						{cta && (
						<div className="db x tc mxa">
							<Link
								{...cta}
								style={{
									backgroundColor: ctaBackgroundColor,
									color:ctaColor
								}}
								className={classNames('button--primary mobile-view-btn', {
									invert: ctaBackgroundColor,
								})}
								aria-label="Igaf"
							/>
						</div>
						)}
					</div>
				</div>
			</div>
		</div>
	</section>
);
