import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Drawer from './Drawer';
import classNames from 'classnames';

const Certification = ({description, logo, title}) => {
	const Trigger = ({title, isOpen, onClick}) => (
		<button
			title={title}
			aria-label={title}
			className="x tl"
			name="toggle"
			onClick={onClick}>
			<span className="df aic">
				<span className="db certification__image--mobile pr2">
					<picture>
						<img alt={logo.alt} src={logo.url} />
					</picture>
				</span>
				<span className="db fg1 fw--800 pr2 tl">{title}</span>
				<span className="db fs0 df aic">
					<img
						src="/images/icon-plus.svg"
						className={classNames('drawer__arrow-flip', {
							active: isOpen,
						})}
						alt="plus icon"
					/>
				</span>
			</span>
		</button>
	);

	const mobileLayout = (
		<div className="hide--sm">
			<Drawer title={title} renderTrigger={Trigger}>
				<div className="pt1 line-break rich-text">
					<BlockContent blocks={description} serializers={richText} />
				</div>
			</Drawer>
		</div>
	);

	const desktopLayout = (
		<div className="show--sm">
			<div className="db certification__image mb3">
				<picture>
					<img alt={logo.alt} src={logo.url} />
				</picture>
			</div>
			<h3 className="fw--800">{title}</h3>
			<div className="sans--sm rich-text line-break">
				<BlockContent blocks={description} serializers={richText} />
			</div>
		</div>
	);

	return (
		<React.Fragment>
			{mobileLayout}
			{desktopLayout}
		</React.Fragment>
	);
};

export default ({certificationGroups = []}) => {
	const groupRows = certificationGroups.map((group, index) => (
		<div
			key={group._key}
			className={classNames({
				'mb3 mb7--lg': index < certificationGroups.length - 1,
			})}>
			<div className="serif--lg serif--xxl--lg mb3 mb10--lg">
				<BlockContent blocks={group.title} serializers={richText} />
			</div>
			<div className="row gutter--large--lg">
				{(group.certifications || []).map(cert => (
					<div
						key={cert._key}
						className="col c6--md c4--lg mb3 mb5--lg">
						<Certification {...cert} />
					</div>
				))}
			</div>
		</div>
	));

	return (
		<div className="bg--gray-bg pt6 pb6 pt12--lg pb12--lg">
			<div className="grid-container contained ">
				<div className="row align--center">
					<div className="col c12 c10--lg">{groupRows}</div>
				</div>
			</div>
		</div>
	);
};
