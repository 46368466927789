import React from 'react';
import scrollTo from 'magic-tricks/lib/scrollTo';
import getDistanceFromTop from '../utils/getDistanceFromTop';
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import richText from '../serializers/richText';
import Drawer from './Drawer';

const onClickHash = (event, id) => {
	const elemHash = document.getElementById(id);
	if (!elemHash) return;
	event.preventDefault();
	scrollTo(getDistanceFromTop(elemHash) - 100); // header height
};

const DrawerTrigger = ({title, isOpen, onClick}) => (
	<button
		title={title}
		aria-label={title}
		className="x tl"
		name="toggle"
		onClick={onClick}>
		<div className="df jcb aic pt2 pb2">
			<div className="fw--800 pr2">{title}</div>
			<div className="fs0 df aic">
				<img
					src="/images/icon-plus.svg"
					className={classNames('drawer__arrow-flip', {
						active: isOpen,
					})}
					alt="plus icon"
				/>
			</div>
		</div>
	</button>
);

export default ({sections}) => (
	<div className="mt4 mb4 mt10--lg mb10--lg pt4 pb4 pt10--lg pb10--lg bg--gray-bg">
		<div className="grid-container contained">
			<div className="row align--center">
				<div className="col c2 show--lg psy info-page__sidebar">
					{sections.map(({_key, title, questions = []}) => (
						<React.Fragment key={_key}>
							{questions.map(({_key, question}) => (
								<div key={_key} className="mb2 sans--xs">
									<a
										aria-label={question}
										onClick={event =>
											onClickHash(event, _key)
										}
										className="sans--sm fw--800 link--opacity"
										href={`#${_key}`}>
										{question}
									</a>
								</div>
							))}
						</React.Fragment>
					))}
				</div>
				<div className="col c1 show--lg" />
				<div className="col c7--lg">
					{sections.map(({_key, title, questions = []}, index) => (
						<div className="mb4" key={_key} id={_key}>
							{title && <h3 className="serif--lg serif--xxl--lg mb2 mb4--lg">
								{title}
							</h3>}
							{questions.map(
								({_key, question, answer}, index) => (
									<div id={_key} key={_key}>
										<div className="mb4 show--sm">
											<h4 className="fw--800">
												{question}
											</h4>
											<div className="line-break rich-text">
												<BlockContent
													blocks={answer}
													serializers={richText}
												/>
											</div>
										</div>
										<div className="hide--sm">
											{index > 0 && <hr />}
											<Drawer
												title={question}
												renderTrigger={DrawerTrigger}>
												<div className="pb2 line-break rich-text">
													<BlockContent
														blocks={answer}
														serializers={richText}
													/>
												</div>
											</Drawer>
										</div>
									</div>
								),
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	</div>
);
