import React from 'react';
import SanityMuxPlayer from "sanity-mux-player";
export default ({
                    backgroundColor = '#f2f2f2',
                    title,
                    storyVideo
                }) => (
    <>
        {storyVideo && (
            <div>
               <h2 className="sans--lg sans--xxl--lg fw--800 mb3 tc">{title}</h2>
                <div style={{backgroundColor}}>
                         <div className="video-container-sp">
                            <SanityMuxPlayer
                                assetDocument={storyVideo.asset}
                                autoload
                                autoplay={true}
                                loop
                                showControls={true}
                                data-keepplaying
                                playsInline
                                poster={storyVideo.url ? `${storyVideo.url}?q=75` : false}
                            />
                        </div>
                    </div>
            </div>
        )}
    </>
);
