import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({width, description = []}) => (
	<div className="grid-container contained mt6 mb6">
		<div className="row align--center">
			<div className={`col ${width}--md c12`}>
				<div className="rich-text line-break mb3 tc">
					<BlockContent blocks={description} serializers={richText} />
				</div>
				<div>
					<div data-crl8-container-id="homepage" />
				</div>
			</div>
		</div>
	</div>
);
