const {fetch} = global;

/*const encodeFormData = (data) => {
	const formData = new FormData();
	
	Object.keys(data).forEach((k)=>{
		formData.append(k, data[k]);
	});

	return formData;
};*/
export const submitForm = formData => {
    // Convert FormData to object
     const formDataObject = {};
     for (const [key, value] of formData.entries()) {
         formDataObject[key] = value;
     }
if(formDataObject['form-name']=='question-or-suggestion-form' || formDataObject['form-name']=='concern-or-complaint'){
  return fetch('/', {
     method: "POST",
	 body: formData,
     });
}
else{
	return fetch('/', {
		method: "POST",
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: new URLSearchParams(formData).toString(),
		});

}
};