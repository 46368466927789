import React from 'react';

export default ({width}) => (
	<div className="grid-container contained">
		<div className="row align--center">
			<div className={`col ${width}--md c12`}>
				<hr />
			</div>
		</div>
	</div>
);
