import React from 'react';
import Link from './Link';

export default ({
                    ourbrandImage,
                    otherbrandImage,
                    ourBrandName,
                    otherBrandName,
                    ourProductPrice,
                    otherProductPrice,
                    ourProductPricePerOz,
                    otherProductPricePerOz,
                    cta,
                    points = []
                }) => (

    <div>
        {ourbrandImage && otherbrandImage && (
            <table class="no-margin images-header diff-between-table" id="get-width" role="presentation">
                <tbody>
                <tr>
                    <td>
                        <picture>
                            <img alt={ourbrandImage.alt} src={`${ourbrandImage.url}?w=220&auto=format&q=75`}/>
                        </picture>
                        {ourProductPrice && otherProductPrice && (
                            <button
                            title="Compare price" aria-label={ourProductPricePerOz && otherProductPricePerOz ? ourProductPrice+ '(' + ourProductPricePerOz + '/oz)' : ourProductPrice}
                                class="compareprice button--pill--primary invert">{ourProductPrice} {ourProductPricePerOz && otherProductPricePerOz ? '(' + ourProductPricePerOz + '/oz)' : ''}</button>
                        )}
                    </td>
                    <td>
                        <div class="vs-inner">
                            vs
                        </div>
                    </td>
                    <td class="challenger-image">
                        <picture>
                            <img alt={otherbrandImage.alt} src={`${otherbrandImage.url}?w=220&auto=format&q=75`}/>
                        </picture>
                        {ourProductPrice && otherProductPrice && (
                            <button
                            title="Compare price" aria-label={ourProductPricePerOz && otherProductPricePerOz ? otherProductPrice+ '(' + otherProductPricePerOz + '/oz)' :otherProductPrice}
                                class="compareprice button--pill--primary invert">{otherProductPrice} {ourProductPricePerOz && otherProductPricePerOz ? '(' + otherProductPricePerOz + '/oz)' : ''}</button>
                        )}
                    </td>
                </tr>
                </tbody>
            </table>
        )}
        {ourBrandName && otherBrandName && points.length > 0 && (
            <div>
                <table class="no-margin brand-header"  role="presentation">
                    <tbody>
                    <tr>
                        <td class="genexa ourbrandname">
                            {ourBrandName && (<p> {ourBrandName} </p>)}
                        </td>
                        <td>
                            {otherBrandName && (<p> {otherBrandName} </p>)}
                        </td>
                    </tr>
                    </tbody>
                </table>

                <table class="main"  role="presentation">
                    <tbody>
                    <tr class="checklist top-row">
                        <td class="sundays">
                            <ul>
                                {points.map(feature => (
                                    <li className={feature.ourpointcolor}>
                                        <div className="tc rich-text line-break">
                                            {feature.ourpoint}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </td>
                        <td class="challenger">
                            <ul>
                                {points.map(feature => (
                                    <li className={feature.otherpointcolor}>
                                        <div className="tc rich-text line-break">
                                            {feature.otherpoint}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </td>
                    </tr>

                    <tr class="buy-links">
                        <td class="sundays">
                            {cta && (
                                <div className="mt3 tc">
                                    <Link {...cta} className="green button" aria-label="Buy product" />
                                </div>
                            )}
                        </td>
                        <td class="challenger"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )}

    </div>
);
