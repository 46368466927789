import React, {useState, useEffect} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Reveal from './Reveal';

const getIframeSrc = () => {
	const host = 'destinilocators.com';
	const client = 'genexa';
	const clientIteration = 'site';
	let systemParameters = 'MM=panel2';

	if (typeof window === 'undefined') return;

	const locator =
		host + '/' + client + '/' + clientIteration + '/locator.php';
	const referrer =
		'?RFR=' + window.location.protocol + '//' + window.location.hostname;
	const customParameters = '';
	const search = location.search.substring(1);
	let getQuery = false;

	try {
		getQuery = JSON.parse(
			'{"' +
				decodeURI(search)
					.replace(/"/g, '\\"')
					.replace(/&/g, '","')
					.replace(/=/g, '":"') +
				'"}',
		);
	} catch (e) {
		console.warn(e);
	}

	let systemParametersQuery = false;

	try {
		systemParametersQuery = JSON.parse(
			'{"' +
				decodeURI(systemParameters)
					.replace(/"/g, '\\"')
					.replace(/&/g, '","')
					.replace(/=/g, '":"') +
				'"}',
		);
	} catch (e) {
		console.warn(e);
	}

	if (systemParametersQuery !== false && getQuery !== false) {
		for (const k in getQuery) {
			systemParametersQuery[k] = getQuery[k];
		}
	} else if (getQuery !== false) {
		systemParametersQuery = getQuery;
	}

	function serialize(obj) {
		return obj !== false
			? '' +
					Object.keys(obj)
						.reduce(function (a, k) {
							a.push(k + '=' + encodeURIComponent(obj[k]));
							return a;
						}, [])
						.join('&')
			: '';
	}

	systemParameters = serialize(systemParametersQuery);

	return `//${locator}${referrer}&${customParameters}&${systemParameters}`;
};

const loadScript = (url, callback) => {
	const head = document.getElementsByTagName('head')[0];
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = url;
	script.onreadystatechange = callback;
	script.onload = callback;
	head.appendChild(script);
};

const loadResizeScript = () => {
	// loadScript('//destinilocators.com/control/gtm.js', function () {});
	// if (window.location.protocol == 'http:') {
	// 	loadScript('//destinilocators.com/control/pscript.js', function () {});
	// } else {
	// 	loadScript(
	// 		'//destinilocators.com/control/pscript_s.js',
	// 		function () {},
	// 	);
	// }
};

export default ({title}) => {
	const [iframeSource, setIframeSource] = useState(null);

	// Load iframe on
	useEffect(() => {
		setIframeSource(getIframeSrc());
		loadResizeScript();
		const script = document.createElement("script");
        script.src = "https://lets.shop/productFirstSnippet.js?id=destini-locator";
        script.async = true;
    
        script.onload = () => {
          // Access functions or variables defined in your_script.js here
         // console.log("Script loaded!");
          // Call functions or do operations with what's available from the script
        };
    
        document.body.appendChild(script);
    
        return () => {
          // Cleanup: remove the script when the component is unmounted
          document.body.removeChild(script);
        };
	}, []);

	return (
		<div className="grid-container contained mt5 mt11--lg mb11" style={{ marginTop: `-90px` }}>
			<Reveal>
				<div className="row align--center mb6 mb10--lg reveal__slide reveal__delay--1">
					<div className="col c10--lg c7--xl">
						<div className="rich-text--hero tc serif--xl serif--xxl--lg serif--xxxl--xl">
							<BlockContent
								blocks={title}
								serializers={richText}
							/>
						</div>
					</div>
				</div>
			</Reveal>
			<div className="row align--center">
				<div className="col c10--lg">
			 {/*	<iframe
						id="destini"
						title="Destini Locator"
						src={iframeSource}
						scrolling="no"
						frameBorder="0"
						allow="geolocation"
						style={{width: '100%', height: 770}}
					/>*/ }
					<div id="destini-locator" class="destini-locator-class" locator-id="2010" alpha-code="7DA" locator-name="Genexa OCL Store Locator" client-id="genexa"></div>
				</div>
			</div>
		</div>
	);
};
