import React from 'react';
import imageAspectRatioStyles from '../utils/imageAspectRatioStyles';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';
import Flickity from './Flickity';
import QuoteOpen from './QuoteOpen';
import QuoteClose from './QuoteClose';
import classNames from 'classnames';

const SLIDE_OPTIONS = {
	cellAlign: 'center',
	contain: true,
	wrapAround: true,
	pageDots: true,
	prevNextButtons: false,
	selectedAttraction: 0.1,
	friction: 0.8,
};

export default ({
	backgroundColor = '#659092',
	foregroundColor = '#B8E8BA',
	images = [],
	title,
	description,
	author,
	cta,
	ctaColor,
	ctaBackgroundColor,
	layout="right"
}) => {
	return (
		<div style={{ backgroundColor }} className={layout == 'right' ? "edge--tall" : ''}>
			<div className="grid-container flush">
				<div 	className={classNames('row gutter--none align--middle testimonial-row', {
					'direction--rev--lg': layout == 'right',
				})}>
					<div className="col c12 c6--lg testimonial-col">
						<div className="testimonial__carousel-container">
							{images.length > 1 ? (
								<Flickity
									options={SLIDE_OPTIONS}
									className="testimonial__carousel">
									{images.map(img => (
										<div
											className="db image size--1x1 x"
											key={img._key}>
											<picture>
												<source
													srcSet={`${img.url}?w=1000&auto=format&q=75`}
													media="(min-width: 800px)"
												/>
												<img
													alt={img.alt}
													src={`${img.url}?w=680&auto=format&q=75`}
												/>
											</picture>
										</div>
									))}
								</Flickity>
							) : (
								<div className="db image size--1x1">
									<picture>
										<source
											srcSet={`${images[0].url}?w=1000&auto=format&q=75`}
											media="(min-width: 800px)"
										/>
										<img
											alt={images[0].alt}
											src={`${images[0].url}?w=680&auto=format`}
										/>
									</picture>
								</div>
							)}
						</div>
					</div>
					<div className="col c1 show--xl" />
					<div
						style={{
							color: foregroundColor,
						}}
						className="col c6--lg c4--xl pt6 pb6 pt4--md pb4--md pr testimonial-col">
						<div className="grid-container contained flush--xl">
							<QuoteOpen className="db testimonial__image--before hide--lg" />
							<div className="testimonial__content pl3--lg pr3--lg pl0--xl pr0--xl">
								<h2 className="sans--lg sans--xxl--lg fw--800 mb3 mb4--lg">
									{title}
								</h2>
								<div className="pr">
									<QuoteOpen className="db testimonial__image--before show--lg" />
									<div className="rich-text line-break">
										<BlockContent
											blocks={description}
											serializers={richText}
										/>
									</div>
									{author && (
										<p className="mt1 sans--xs">{author}</p>
									)}
									<QuoteClose className="db testimonial__image--after show--lg" />
								</div>
								{cta && (
									<div className="mt3 mt6--lg">
										<Link
											{...cta}
											className="button--primary invert"
											style={{
												backgroundColor: ctaBackgroundColor?ctaBackgroundColor:foregroundColor,
												'--borderColor': ctaBackgroundColor?ctaBackgroundColor:foregroundColor,
												color: ctaColor?ctaColor:backgroundColor,
											}}
											aria-label="Testimonial"
										/>
									</div>
								)}
							</div>
						</div>
						<QuoteClose className="db testimonial__image--after hide--lg" />
					</div>
					<div className="col c1 show--xl" />
				</div>
			</div>
		</div>
	);
};
