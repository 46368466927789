import React, {useEffect, useRef} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import SanityMuxPlayer from 'sanity-mux-player';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';
import onClickHash from '../utils/onClickHash';

const HeroCarouselImageSlide = ({
	_key,
	layout,
	desktopBackground,
	mobileBackground,
	title,
	description,
	cta,
	videoCta,
	backgroundVideo,
	modalVideo,
	toggleVideoModalOpen,
	isModalOpen,
	setActiveVideo,
}) => {
	const desktopVideoWrapper = useRef(null);
	const mobileVideoWrapper = useRef(null);

	useEffect(() => {
		const {current: desktopEl} = desktopVideoWrapper;
		const {current: mobileEl} = mobileVideoWrapper;

		const desktopVideo = desktopEl
			? desktopEl.querySelector('video')
			: null;
		const mobileVideo = mobileEl ? mobileEl.querySelector('video') : null;

		if (isModalOpen) {
			desktopVideo && desktopVideo.pause();
			mobileVideo && mobileVideo.pause();
		} else {
			desktopVideo && desktopVideo.play();
			mobileVideo && mobileVideo.play();
		}
	}, [isModalOpen, desktopVideoWrapper.current, mobileVideoWrapper.current]);

	const bgVideoPlayer = (
		<div ref={desktopVideoWrapper}>
			<SanityMuxPlayer
				assetDocument={backgroundVideo.asset}
				className="home-hero__background show--lg"
				autoload
				autoplay
				loop
				muted
				showControls={false}
				data-keepplaying
				playsInline
				poster={`${desktopBackground.url}?q=75`}
			/>
		</div>
	);

	const bgMobileVideoPlayer = (
		<div ref={mobileVideoWrapper}>
			<SanityMuxPlayer
				assetDocument={backgroundVideo.asset}
				autoload
				autoplay
				loop
				muted
				showControls={false}
				data-keepplaying
				playsInline
				poster={`${mobileBackground.url}?q=75`}
			/>
		</div>
	);

	const heroContents = (
		<div className="x">
			<div
				className={classNames('grid-container contained x', {
					'pt11--lg': layout === 'center',
				})}>
				<div className={classNames('row', `align--${layout}`)}>
					<div className="col c1 show--sm" />

					<div
						className={classNames('col', {
							'c10--sm c6--lg c4--xl': layout !== 'center',
							'c10--sm c8--lg': layout === 'center',
						})}>
						<div className="rich-text--hero tc serif--lg serif--xl--sm serif--xxl--lg reveal__slide reveal__delay--2">
							<BlockContent
								blocks={title}
								serializers={richText}
							/>
						</div>

						{description && (
							<div className="tc rich-text line-break mt2 reveal__slide reveal__delay--3">
								<BlockContent
									blocks={description}
									serializers={richText}
								/>
							</div>
						)}

						{cta && (
							<div className="mt3 tc reveal__slide reveal__delay--4">
								<Link
									{...cta}
									onClick={event =>
										onClickHash(event, cta.url)
									}
									className="button--primary"
									aria-label="Slide"
								/>
							</div>
						)}

						{videoCta && modalVideo && (
							<div className="mt3 tc reveal__slide reveal__delay--4">
								<button
									title={videoCta} 
									aria-label={videoCta} 
									onClick={() => setActiveVideo(modalVideo)}
									className="button--primary">
									{videoCta}
								</button>
							</div>
						)}
					</div>
					<div className="col c1 show--sm" />
				</div>
			</div>
		</div>
	);

	return (
		<div key={_key} className="home-hero pr x pt3 pt0--lg">
			{bgVideoPlayer}
			<div
				className={classNames('x y push-up df fdc jcb', {
					'jcc--lg': layout !== 'center',
				})}>
				{heroContents}

				<div className="hide--lg x mt3">{bgMobileVideoPlayer}</div>
			</div>
		</div>
	);
};

export default HeroCarouselImageSlide;
