import React, { Component } from 'react';
import reduce from 'lodash/reduce';
import { email as emailRegex } from 'magic-tricks/lib/regex';
import Select from './Select';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames';
import { subscribe } from 'klaviyo-subscribe';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';

const HONEYPOT_NAME = 'bt-fld';

export default class FullWidthKlaviyoForm extends Component {
	constructor({ fields = [] }) {
		super();

		const formValues = reduce(
			fields,
			(initialFieldValues, field) => ({
				...initialFieldValues,
				[field.name]: '',
			}),
			{},
		);

		const formErrors = reduce(
			fields,
			(initialFieldValues, field) => ({
				...initialFieldValues,
				[field.name]: false,
			}),
			{},
		);

		this.state = {
			formValues,
			showErrors: false,
			formErrors,
			submitError: null,
			isSubmitting: false,
			isSuccess: false,
		};
	}

	handleSubmit = e => {
		e.preventDefault();

		const errors = this.validateForm();

		if (errors) {
			return;
		}

		const { values } = this.state;
		const { klaviyo_list_id } = this.props;

		this.setState({
			isSubmitting: true,
		});

		const data = {
			'form-name': this.props.form.id,
			...values,
		};

		subscribe(klaviyo_list_id, data.email, {
			$first_name: data['first-name'],
			$last_name: data['last-name'],
			$phone_number: data['Phone'],
			$zip: data['Zip-Code'],
			$city: data['city'],
			$region: data['state'],
			$address1: data['street-address'],
		}).then(() => {
			this.setState({
				isSubmitting: false,
				isSuccess: true,
			});
		})
			.catch(submitError => {
				console.warn(submitError);

				this.setState({
					isSubmitting: false,
					submitError,
				});
			});
	};

	validateForm = () => {
		const { values = {}, formErrors = {} } = this.state;
		const { fields = [] } = this.props.form;

		const requiredFields = filter(fields, field => field.required);
		const emailFields = filter(
			fields,
			field => field._type === 'formInputEmail',
		);
		const updatedFormErrors = { ...formErrors };

		let showErrors = false;

		forEach(requiredFields, field => {
			if (!values[field.name]) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		forEach(emailFields, field => {
			if (!emailRegex.test(values[field.name])) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		this.setState({
			showErrors,
			formErrors: updatedFormErrors,
		});

		return showErrors;
	};

	onChangeFileInput = (name, files) => {
		const { values = {} } = this.state;

		this.setState(
			{
				values: {
					...values,
					[name]: files,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	onChangeInput = event => {
		const { values = {} } = this.state;

		this.setState(
			{
				values: {
					...values,
					[event.target.name]: event.target.value,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	onChangeSelect = (name, value) => {
		const { values = {} } = this.state;

		this.setState(
			{
				values: {
					...values,
					[name]: value,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	render() {
		const { cta, fields = [], id } = this.props.form;

		const {
			values = {},
			isSuccess,
			isSubmitting,
			showErrors,
			formErrors = {},
		} = this.state;

		const fieldRows = fields.map(field => {
			switch (field._type) {
				case 'formTitle':
					return (
						<div key={field._key} className="col c10--md mt2 mb2">
							{!field.blank && (
								<h3 className="fw--800">{field.title}</h3>
							)}
						</div>
					);

				case 'formInputText':
					return (
						<div key={field._key} className="col c5--md mb2">
							<input
								name={field.name}
								aria-label={field.placeholder}
								value={values[field.name]}
								onChange={this.onChangeInput}
								type="text"
								className="x input--primary"
								placeholder={`${field.placeholder}${field.required ? '*' : ''
									}`}
							/>
							{showErrors && formErrors[field.name] === true && (
								<p className="mt1 color--red sans--xs fw--800">
									This field is required.
								</p>
							)}
						</div>
					);

				case 'formInputTextArea':
					return (
						<div key={field._key} className="col c10--md mb2">
							<Textarea
								key={field._key}
								minRows={3}
								name={field.name}
								aria-label={field.placeholder}
								value={values[field.name]}
								onChange={this.onChangeInput}
								className="db x input--primary"
								placeholder={`${field.placeholder}${field.required ? '*' : ''
									}`}
							/>
							{showErrors && formErrors[field.name] === true && (
								<p className="mt1 color--red sans--xs fw--800">
									This field is required.
								</p>
							)}
						</div>
					);
				case 'formInputEmail':
					return (
						<div key={field._key} className="col c5--md mb2">
							<input
								key={field._key}
								name={field.name}
								aria-label={field.placeholder}
								value={values[field.name]}
								onChange={this.onChangeInput}
								type="email"
								className="x input--primary"
								placeholder={`${field.placeholder}${field.required ? '*' : ''
									}`}
							/>
							{showErrors && formErrors[field.name] === true && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter a valid email.
								</p>
							)}
						</div>
					);
				case 'formInputTel':
					return (
						<div key={field._key} className="col c5--md mb2">
							<input
								key={field._key}
								name={field.name}
								aria-label={field.placeholder}
								value={values[field.name]}
								onChange={this.onChangeInput}
								type="tel"
								className="x input--primary"
								placeholder={`${field.placeholder}${field.required ? '*' : ''
									}`}
							/>
							{showErrors && formErrors[field.name] === true && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter a valid telephone number.
								</p>
							)}
						</div>
					);
				case 'formInputSelect':
					return (
						<div key={field._key} className="col c5--md mb2">
							<Select
								label={`${field.placeholder}${field.required ? '*' : ''
									}`}
								value={values[field.name]}
								onChange={value =>
									this.onChangeSelect(field.name, value)
								}
								triggerClassName="input--primary x df fdr jcb aic"
								options={field.options.map(option => ({
									title: option,
									value: option,
								}))}
								dropdownAlignment="top left right"
							/>
							{showErrors && formErrors[field.name] === true && (
								<p className="mt1 color--red sans--xs fw--800">
									Please select an option.
								</p>
							)}
						</div>
					);
				default:
					break;
			}
		});

		const honeypot = (
			<input
				name={HONEYPOT_NAME}
				aria-label={HONEYPOT_NAME}
				style={{
					position: 'absolute',
					top: -2000,
					left: -2000,
					height: 0,
					width: 0,
					overflow: 'hidden',
				}}
			/>
		);

		return (
			<div className="grid-container contained mt4 mb4 mt12--lg mb12--lg">
				<form
					title={id}
					aria-label={id}
					className="x db"
					name={id}
					onSubmit={this.handleSubmit}>
					{honeypot}

					<div className="row align--center mb4">{fieldRows}</div>

					<div className="df fdr jcc">
						<button
							title={cta || 'Submit'}
							aria-label={cta || 'Submit'}
							type="submit"
							disabled={isSubmitting}
							className={classNames('button--primary db', {
								error: showErrors,
								'cs--loading': isSubmitting,
							})}>
							{cta || 'Submit'}
						</button>
					</div>

					{isSuccess && (
						<p className="form--success mb2 mt2 sans--sm tc">
							Thanks for submitting!
						</p>
					)}
				</form>
			</div>
		);
	}
}
