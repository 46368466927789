import getDistanceFromTop from './getDistanceFromTop';

const onClickHash = (event, id) => {
	const elemHash = document.querySelector(id);

	if (!elemHash) return;

	event.preventDefault();

	const headerHeight = window.innerWidth >= 1000 ? 80 : 60;
	const bannerHeight = 35;

	scrollTo(getDistanceFromTop(elemHash) - headerHeight - bannerHeight);
};

export default onClickHash;
