import React, {useState} from 'react';
import {Transition} from 'react-transition-group';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export const MODAL_TRANSITION_DURATION = 800;

const UNDERLAY_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	entered: {
		opacity: 1,
		transform: 'translate(-50%, -50%)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	exited: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
};

export default ({doctors = []}) => {
	const [activeDoctorIndex, setActiveDoctorIndex] = useState(null);
	const [showDoctorModal, setShowDoctorModal] = useState(false);
	const activeDoctor = doctors[activeDoctorIndex];

	const modalContents = activeDoctor && (
		<div className="row gutter--none">
			<div className="col c6 show--md">
				<div className="image size--3x4">
					<picture>
						<source
							srcSet={`${activeDoctor.image.url}?w=900&auto=format&q=75`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${activeDoctor.image.url}?w=600&auto=format&q=75`}
							media="(min-width: 600px)"
						/>
						<img
							alt={activeDoctor.image.alt}
							src={`${activeDoctor.image.url}?w=400&auto=format`}
						/>
					</picture>
				</div>
			</div>
			<div className="col c6--md">
				<div className="p2 pt4 pb4 pt6--md pb6--md">
					<h2 className="serif--lg serif--xxl--lg mb1">
						{activeDoctor.name}
					</h2>
					<p className="fw--800 mb1">{activeDoctor.description}</p>
					<div className="rich-text line-break">
						<BlockContent
							blocks={activeDoctor.biography}
							serializers={richText}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	const doctorModal = (
		<Transition
			in={showDoctorModal}
			appear
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<React.Fragment>
					<div
						onClick={() => setShowDoctorModal(false)}
						className="doctor-modal__underlay pf fill"
						style={{
							...UNDERLAY_TRANSITION_STYLES.default,
							...UNDERLAY_TRANSITION_STYLES[status],
						}}
					/>
					<div
						className="doctor-modal pf bg--white"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							...MODAL_TRANSITION_STYLES[status],
						}}>
						{modalContents}
						<div className="pa top right p2 pen">
							<button
								title="Close"
								aria-label="Close"
								onClick={() => setShowDoctorModal(false)}
								className="db link--opacity pea">
								<img
									className="db"
									src="/images/icon-modal-close.svg"
									alt="Close"
								/>
							</button>
						</div>
					</div>
				</React.Fragment>
			)}
		</Transition>
	);

	const doctorColumns = doctors.map((doctor, index) => (
		<div key={doctor._key} className="col c12 c4--md mb5">
			<div className="link--trigger pr">
				<div className="pl4--xl pr4--xl">
					<div className="db image size--1x1 mb3 link--opacity">
						<picture>
							<source
								srcSet={`${doctor.image.url}?w=650&h=650&fit=crop&crop=top&auto=format&q=75`}
								media="(min-width: 600px)"
							/>
							<img
								alt={doctor.image.alt}
								src={`${doctor.image.url}?w=400&h=400&fit=crop&crop=top&auto=format&q=75`}
							/>
						</picture>
					</div>
				</div>
				<div className="pa fill df fdc jcc aic link--hidden">
					<button
						title="Learn More"
						aria-label="Learn More"
						onClick={() => {
							setActiveDoctorIndex(index);
							setShowDoctorModal(true);
						}}
						className="button--primary">
						Learn More
					</button>
				</div>
			</div>

			<h3 className="fw--800 tc">{doctor.name}</h3>
			<p className="tc">{doctor.description}</p>
		</div>
	));

	return (
		<React.Fragment>
			<div className="grid-container contained mt12 mb12">
				<div className="row align--center">
					<div className="col c10--lg">
						<div className="row gutter--large--lg align--center">
							{doctorColumns}
						</div>
					</div>
				</div>
			</div>
			{doctorModal}
		</React.Fragment>
	);
};
