import React from 'react';

export default ({width}) => (
	<div className="grid-container contained mt12 mb12">
		<div className="row align--center">
			<div className={`col ${width}--md c12`}>
				<div className="product-reviews">
					<div data-bv-show="seller_ratings" />
				</div>
			</div>
		</div>
	</div>
);
