import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';
import classNames from "classnames";

export default ({title, description = [], cta, ctaColor, doctors = []}) => (
	<div className="grid-container contained mt12 mb12">
		<div className="row align--center mb2">
			<div className="col c6 c4--lg tc">
				<h2 className="serif--lg serif--xl--md tc">{title}</h2>
			</div>
		</div>
		<div className="row align--center mb4">
			<div className="col c8 c6--lg subheading">
				<BlockContent blocks={description} serializers={richText} />
			</div>
		</div>

		<div className="row align--center">
			<div className="col c12--lg">
				<div className="row align--center">
					{doctors.map((doctor, index) => (
						<div key={doctor._key} className="col c12 c3--md mb5">
							<div className="pl4--xl pr4--xl">
								<div className="db image size--1x1 mb3">
									<picture>
										<source
											srcSet={`${doctor.image.url}?w=650&h=650&fit=crop&crop=top&auto=format&q=75`}
											media="(min-width: 600px)"
										/>
										<img
											alt={doctor.image.alt}
											src={`${doctor.image.url}?w=400&h=400&fit=crop&crop=top&auto=format&q=75`}
										/>
									</picture>
								</div>
							</div>

							<h3 className="fw--800 tc">{doctor.title}</h3>

							<div className="tc show--md mt1 rich-text pl4--lg pr4--lg">
								<BlockContent
									blocks={doctor.description}
									serializers={richText}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>

		{cta && (
			<div className="db x tc mxa mt2 mt4--md">
				<Link
					{...cta}
					style={{
						backgroundColor: ctaColor,
						'--borderColor': ctaColor,
					}}
					className={classNames('button--primary', {
						invert: ctaColor,
					})}
					aria-label="Doctors Bio"
				/>
			</div>
		)}
	</div>
);
