import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import imageAspectRatioStyles from '../utils/imageAspectRatioStyles';
import Plyr from './Plyr';

export default ({title, subtitle, description = [], media}) => {
	let mediaBlock;

	if (media._type === 'image') {
		mediaBlock = (
			<div
				className="image x"
				style={imageAspectRatioStyles(media.dimensions.aspectRatio)}>
				<picture>
					<source
						srcSet={`${media.url}?w=900&auto=format&q=75`}
						media="(min-width: 1000px)"
					/>
					<source
						srcSet={`${media.url}?w=600&auto=format&q=75`}
						media="(min-width: 600px)"
					/>
					<img
						alt={media.alt}
						src={`${media.url}?w=400&auto=format`}
					/>
				</picture>
			</div>
		);
	} else if (media._type === 'youtube') {
		let embedhtml = media.oEmbed.html.replace("allowfullscreen", "allowfullscreen" + ` title=${media.oEmbed.title}`);
		mediaBlock = <Plyr html={embedhtml} />;
	}

	return (
		<div className="mt12 mb12 grid-container contained">
			<div className="row align--center">
				<div className="col c6--md mb3 mb0--md">{mediaBlock}</div>

				<div className="col c1 show--lg" />

				<div className="col c6--md c4--lg c3--xl">
					<h2 className="serif--lg serif--xxl--lg mb1">{title}</h2>
					{subtitle && <p className="fw--800 mb1">{subtitle}</p>}
					<div className="rich-text line-break">
						<BlockContent
							blocks={description}
							serializers={richText}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
