import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({
	text,
	image,
}) => (
	<section>
		<div className="grid-container flush--lg">
			<div className="row gutter--none align--middle">
				<div className="col c1 show--xl"></div>
				<div className="col c6--lg c4--xl  pt4--md pb4--md pr">
					<div className="sans--lg mb3 mb4--lg pl12--lg sidebysideimagetext">
						<BlockContent
							blocks={text}
							serializers={richText}
						/>
					</div>
				</div>
				<div className="col c1 show--xl"></div>
				<div className="col c12 c6--lg">
					<picture>
						<source
							srcSet={`${image.url}?w=1000&auto=format&q=75`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${image.url}?w=600&auto=format&q=75`}
							media="(min-width: 600px)"
						/>
						<img
							alt={image.alt}
							src={`${image.url}?w=400&auto=format`}
						/>
					</picture>
				</div>
			</div>
		</div>
	</section>
);
