import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';

const MAX_ITEMS = 6;

export default class PressGrid extends React.Component {
	constructor(props) {
		super();

		this.state = {
			isListExpanded: false,
		};
	}

	render() {
		const {title, pressItems = []} = this.props;
		const {isListExpanded} = this.state;
		const truncatedList = pressItems.slice(0, MAX_ITEMS);
		const activeList = isListExpanded ? pressItems : truncatedList;

		const showViewAllButton =
			!isListExpanded && pressItems.length > truncatedList.length;

		const pressColumns = activeList.map(press => (
			<div key={press._key} className="col c6--md c4--lg mb5 mb8--lg">
				<div className="df fdr fdc--md">
					<img
						className="db press__logo mb4--md mr2 mr0--md"
						alt={press.logo.alt}
						src={`${press.logo.url}?w=300&auto=format&q=75`}
					/>
					<div className="fg1 x">
						<h3 className="fw--800 mb1">{press.title}</h3>
						<div className="show--md line-break rich-text mb1">
							<BlockContent
								blocks={press.description}
								serializers={richText}
							/>
						</div>
						<Link
							className="link--underline-invert"
							{...press.url}
							aria-label="Press"
						/>
					</div>
				</div>
			</div>
		));

		return (
			<div className="grid-container contained pt5 pb5 pt12--lg pb12--lg bg--gray-bg">
				<div className="row align--center">
					<div className="col c12 c10--lg">
						<h2 className="serif--lg serif--xl--md mb4 mb12--md">
							{title}
						</h2>
						<div className="row mb4 gutter--large--lg">
							{pressColumns}
						</div>
						{showViewAllButton && (
							<button
								title="View All"
								aria-label="View All" 
								onClick={() =>
									this.setState({
										isListExpanded: true,
									})
								}
								className="link--trigger">
								<span className="dib vam link--underline-invert">
									View All
								</span>
								<img
									className="dib vam ml1"
									src="/images/icon-arrow-press-grid.svg"
									alt="arrow down icon"
								/>
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}
}
