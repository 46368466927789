import React from 'react';

export default ({
	certifications=[],
	backgroundColor
}) => (
	<section className="certification-logo pb0--lg pb3 pt4" style={{
		backgroundColor: backgroundColor,
	}}>
		<div className="grid-container contained">
			<div className="row align--center">
				<div className="col c4--xl">
					<div className="flex__wrapper jcb pr3--lg pl3--lg">
						{certifications.map(certification => (
							<div className="pr1 pl1" key={certification._key}>
								<div className="tc">
									<img alt={certification.image.alt} src={certification.image.url} />
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

		</div>
	</section>
);
