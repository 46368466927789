import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';
import imageAspectRatioStyles from '../utils/imageAspectRatioStyles';
import onClickHash from '../utils/onClickHash';

const HeroCarouselImageSlide = ({
									_key,
									layout,
									desktopBackground,
									mobileBackground,
									title,
									description,
									cta,
									ctaColor,
									ctaBackgroundColor
								}) => (
	<div key={_key} className="home-hero pr x pt3 pt0--lg">
		<div className="home-hero__background show--lg">
			<picture>
				<source
					srcSet={`${desktopBackground.url}?w=2000&auto=format&q=75`}
					media="(min-width: 1000px)"
				/>
				<source
					srcSet={`${desktopBackground.url}?w=1000&auto=format&q=75`}
					media="(min-width: 400px)"
				/>
				<img
					alt={desktopBackground.alt}
					src={`${desktopBackground.url}?w=680&auto=format&q=75`}
				/>
			</picture>
		</div>
		<div
			className={classNames('x y push-up df fdc jcb', {
				'jcc--lg': layout !== 'center',
			})}>
			<div className="x">
				<div
					className={classNames('grid-container contained', {
						'pt11--lg': layout === 'center',
					})}>
					<div className={classNames('row', `align--${layout}`)}>
						<div className="col c1 show--sm" />

						<div
							className={classNames('col', {
								'c10--sm c6--lg c4--xl': layout !== 'center',
								'c10--sm c8--lg': layout === 'center',
							})}>
							<div className="rich-text--hero tc serif--lg serif--xl--sm serif--xxl--lg reveal__slide reveal__delay--2">
								<BlockContent
									blocks={title}
									serializers={richText}
								/>
							</div>

							{description && (
								<div className="tc rich-text line-break mt2 reveal__slide reveal__delay--3">
									<BlockContent
										blocks={description}
										serializers={richText}
									/>
								</div>
							)}

							{cta && (
								<div className="mt3 tc reveal__slide reveal__delay--4">
									<Link
										{...cta}
										onClick={event =>
											onClickHash(event, cta.url)
										}
										className="button--primary"
										aria-label="Slide"
										style={{
											backgroundColor: ctaBackgroundColor,
											'--borderColor': ctaBackgroundColor,
											color: ctaColor,
										}}
									/>
								</div>
							)}
						</div>
						<div className="col c1 show--sm" />
					</div>
				</div>
			</div>

			<div
				className="image hide--lg x mt3"
				style={imageAspectRatioStyles(
					mobileBackground.dimensions.aspectRatio,
				)}>
				<picture>
					<source
						srcSet={`${mobileBackground.url}?w=2000&auto=format&q=75`}
						media="(min-width: 1000px)"
					/>
					<source
						srcSet={`${mobileBackground.url}?w=1000&auto=format&q=75`}
						media="(min-width: 400px)"
					/>
					<img
						alt={mobileBackground.alt}
						src={`${mobileBackground.url}?w=680&auto=format&q=75`}
					/>
				</picture>
			</div>
		</div>
	</div>
);

export default HeroCarouselImageSlide;
