import React, {Component} from 'react';
import Flickity from './Flickity';

const SLIDE_OPTIONS = {
	cellAlign: 'left',
	wrapAround: true,
	prevNextButtons: false,
	pageDots: false,
	selectedAttraction: 0.1,
	friction: 0.8,
};

export default class InstagramGrid extends Component {
	constructor() {
		super();
		this.options = {
			...SLIDE_OPTIONS,
		};
	}

	onClickPrevious = () => {
		if (!this.slider) return;

		this.slider.previous();
	};

	onClickNext = () => {
		if (!this.slider) return;

		this.slider.next();
	};

	render() {
		const {
			title,
			images = [],
			backgroundColor,
			foregroundColor,
		} = this.props;

		return (
			<div
				className="instagram-grid grid-container contained pt10 pb10 of--hidden"
				style={{backgroundColor, color: foregroundColor}}>
				<div className="row align--center">
					<div className="col c10">
						<h2 className="sans--lg sans--xxl--md fw--700 mb5">
							{title}
						</h2>
						<div className="pr instagram-grid">
							<Flickity
								sliderRef={slider => (this.slider = slider)}
								options={SLIDE_OPTIONS}>
								{images.map(image => (
									<div
										key={image._key}
										className="instagram-grid__image">
										<div className="image size--1x1">
											<picture>
												<source
													srcSet={`${image.url}?w=600&auto=format&q=75`}
													media="(min-width: 1000px)"
												/>
												<source
													srcSet={`${image.url}?w=500&auto=format&q=75`}
													media="(min-width: 600px)"
												/>
												<img
													alt={image.alt}
													src={`${image.url}?w=400&auto=format&q=75`}
												/>
											</picture>
										</div>
									</div>
								))}
							</Flickity>
							<button
								title="Previous"
								aria-label="Previous" 
								name="previous"
								onClick={this.onClickPrevious}
								className="instagram-grid--before-btn show--md link--opacity">
								<img
									className="db"
									alt="previous"
									src="/images/carousel-previous.svg"
								/>
							</button>
							<button
								title="Next"
								aria-label="Next" 
								name="next"
								onClick={this.onClickNext}
								className="instagram-grid--next-btn show--md link--opacity">
								<img
									className="db"
									alt="next"
									src="/images/carousel-next.svg"
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
