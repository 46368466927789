import React, {useRef, useEffect, useState} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';
import SanityMuxPlayer from 'sanity-mux-player';
import onClickHash from '../utils/onClickHash';
import {Transition} from 'react-transition-group';
import {Fragment} from 'react';
import Reveal from './Reveal';

export const MODAL_TRANSITION_DURATION = 800;

const UNDERLAY_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	entered: {
		opacity: 1,
		transform: 'translate(-50%, -50%)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	exited: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
};

export default ({
	mobileBackground,
	desktopBackground,
	layout,
	title,
	description,
	cta,
	videoCta,
	backgroundVideo,
	modalVideo,
}) => {
	const desktopVideoWrapper = useRef(null);
	const mobileVideoWrapper = useRef(null);
	const [isModalOpen, setOpen] = useState(false);
	const [activeVideo, setActiveVideo] = useState(null);

	const modalContents = (
		<div className="x">
			<SanityMuxPlayer
				assetDocument={activeVideo ? activeVideo.asset : null}
				className="mb1 x"
				autoload
				showControls
				data-keepplaying
			/>
			<div className="df jce">
				<button
					title="Close" 
					aria-label="Close"
					className="color--white"
					onClick={() => {
						setOpen(false);
					}}>
					Close
				</button>
			</div>
		</div>
	);

	const videoModal = (
		<Transition
			in={isModalOpen}
			appear
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<React.Fragment>
					<div
						onClick={() => setOpen(false)}
						className="home-hero-video-modal__underlay pf fill"
						style={{
							...UNDERLAY_TRANSITION_STYLES.default,
							...UNDERLAY_TRANSITION_STYLES[status],
						}}
					/>
					<div
						className="x pa home-hero-video-modal__content"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							...MODAL_TRANSITION_STYLES[status],
						}}>
						{modalContents}
					</div>
				</React.Fragment>
			)}
		</Transition>
	);

	useEffect(() => {
		const {current: desktopEl} = desktopVideoWrapper;
		const {current: mobileEl} = mobileVideoWrapper;

		const desktopVideo = desktopEl
			? desktopEl.querySelector('video')
			: null;
		const mobileVideo = mobileEl ? mobileEl.querySelector('video') : null;

		if (isModalOpen) {
			desktopVideo && desktopVideo.pause();
			mobileVideo && mobileVideo.pause();
		} else {
			desktopVideo && desktopVideo.play();
			mobileVideo && mobileVideo.play();
		}
	}, [isModalOpen, desktopVideoWrapper.current, mobileVideoWrapper.current]);

	const bgVideoPlayer = backgroundVideo && (
		<div ref={desktopVideoWrapper}>
			<SanityMuxPlayer
				assetDocument={backgroundVideo.asset}
				className="home-hero__background show--lg"
				autoload
				autoplay
				loop
				muted
				showControls={false}
				data-keepplaying
				playsInline
				poster={`${desktopBackground.url}?q=75`}
			/>
		</div>
	);

	const bgMobileVideoPlayer = backgroundVideo && (
		<div ref={mobileVideoWrapper}>
			<SanityMuxPlayer
				assetDocument={backgroundVideo.asset}
				autoload
				autoplay
				loop
				muted
				showControls={false}
				data-keepplaying
				playsInline
				poster={`${mobileBackground.url}?q=75`}
			/>
		</div>
	);

	const heroContents = (
		<div className="x">
			<div
				className={classNames('grid-container contained x', {
					'pt11--lg': layout === 'center',
				})}>
				<div className={classNames('row', `align--${layout}`)}>
					<div className="col c1 show--sm" />

					<div
						className={classNames('col', {
							'c10--sm c6--lg c4--xl': layout !== 'center',
							'c10--sm c8--lg': layout === 'center',
						})}>
						<div className="rich-text--hero tc serif--lg serif--xl--sm serif--xxl--lg reveal__slide reveal__delay--2">
							<BlockContent
								blocks={title}
								serializers={richText}
							/>
						</div>

						{description && (
							<div className="tc rich-text line-break mt2 reveal__slide reveal__delay--3">
								<BlockContent
									blocks={description}
									serializers={richText}
								/>
							</div>
						)}

						{cta && (
							<div className="mt3 tc reveal__slide reveal__delay--4">
								<Link
									{...cta}
									onClick={event =>
										onClickHash(event, cta.url)
									}
									className="button--primary"
									aria-label="Slide"
								/>
							</div>
						)}

						{videoCta && modalVideo && (
							<div className="mt3 tc reveal__slide reveal__delay--4">
								<button
									title={videoCta} 
									aria-label={videoCta} 
									onClick={() => {
										setActiveVideo(modalVideo);
										setOpen(true);
									}}
									className="button--primary">
									{videoCta}
								</button>
							</div>
						)}
					</div>
					<div className="col c1 show--sm" />
				</div>
			</div>
		</div>
	);

	return (
		<Fragment>
			<Reveal>
				<div className="home-hero pr x pt3 pt0--lg">
					{bgVideoPlayer}
					<div
						className={classNames('x y push-up df fdc jcb', {
							'jcc--lg': layout !== 'center',
						})}>
						{heroContents}

						<div className="hide--lg x mt3">
							{bgMobileVideoPlayer}
						</div>
					</div>
				</div>
			</Reveal>
			{videoModal}
		</Fragment>
	);
};
