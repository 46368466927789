import React from 'react';

export default props => (
	<svg width="10" height="20" viewBox="0 0 10 20" fill="none" {...props}>
		<path
			d="M1.00195 18.6992L7.80502 9.85006L1.00196 1.0009"
			stroke="currentColor"
			strokeWidth="2"
		/>
	</svg>
);
