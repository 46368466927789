import React from 'react';
import classNames from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({description = [], layout, width, textSize = 'sm'}) => (
	<div className="mt12 mb12 grid-container contained">
		<div className="row align--center">
			<div
				className={classNames('col', {
					'c10--lg': width === 'c10',
					'c8--lg': width === 'c8',
					'c6--lg': width === 'c6',
				})}>
				<div
					className={classNames(
						'rich-text line-break',
						`sans--${textSize}`,
						{
							tl: layout === 'left',
							tc: layout === 'center',
							tr: layout === 'right',
						},
					)}>
					<BlockContent blocks={description} serializers={richText} />
				</div>
			</div>
		</div>
	</div>
);
