 import React,{ useState } from 'react';
  const Appform = (name,optionlist) => {
 	const [formname, setformname] = useState('');

 	const handleformChange = (event) => {
 	  setformname(event.target.value);

	const currentForm=event.target.value;

 	document.querySelectorAll('form.forms').forEach(function(elem) {
 		elem.style.display = 'none';
	});
	if(currentForm){
	document.querySelector('#'+currentForm+'').style.display = 'block';
	}
 	};
	 const CapitalizeWord = (formname) => {

		var splitStr = formname.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			// You do not need to check if i is larger than splitStr length, as your for does that for you
			// Assign it back to the array
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
		}
		// Directly return the joined string
		return splitStr.join(' '); 

	 }
		
 return (
 		<div className="grid-container contained mt4 mb4 mt12--lg mb12--lg" style={{ marginTop: `-50px` }}>
 			<div className="row align--center mb4">
			 <div className=''>
			 <div className="tc fw--800 sans--md mb4 mb6--lg pr2 pl2">
 						{name}
 					</div>
					 </div>
				<div className='col c5--md mb2'>
             <select title="form options" aria-label='form options' name="formoption" id="formoption" value={formname} onChange={handleformChange} className='input--primary x df fdr jcb aic'>
             <option value="">Select form</option>	
 			{optionlist.map(optionval => (
 							<option value={optionval}> {CapitalizeWord(optionval.replace(/-/g, ' '))}</option>
 						))}
             </select>
			</div>
			</div>
		</div>
 )

 }
 

 export default ({
 	name,
 	optionlist=[],
	
 }) => (
 <section>
 {Appform(name,optionlist)}
	</section> 
 );
 
