import React from 'react';
import imageAspectRatioStyles from '../utils/imageAspectRatioStyles';
import Plyr from './Plyr';

export default ({title, subtitle, description = [], media}) => {
	let mediaBlock;

	if (media._type === 'image') {
		mediaBlock = (
			<div
				className="image x"
				style={imageAspectRatioStyles(media.dimensions.aspectRatio)}>
				<picture>
					<source
						srcSet={`${media.url}?w=900&auto=format&q=75`}
						media="(min-width: 1000px)"
					/>
					<source
						srcSet={`${media.url}?w=600&auto=format&q=75`}
						media="(min-width: 600px)"
					/>
					<img
						alt={media.alt}
						src={`${media.url}?w=400&auto=format`}
					/>
				</picture>
			</div>
		);
	} else if (media._type === 'youtube') {
		let embedhtml = media.oEmbed.html.replace("allowfullscreen", "allowfullscreen" + ` title=${media.oEmbed.title}`);
		mediaBlock = <Plyr html={embedhtml} />;
	}

	return <div className="of--hidden">{mediaBlock}</div>;
};
