import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';

export default ({
	title,
	description,
	cta,
	foregroundColor,
	backgroundColor,
}) => (
	<div
		style={{
			backgroundColor: backgroundColor,
			color: foregroundColor,
		}}
		className="pb6 pt6 pb10--md pt10--md">
		<div className="grid-container contained">
			<div className="row align--center">
				<div className="col c4--md">
					<h2 className="sans--lg sans--xxl--lg fw--800 mb2 duotone-header">
						{title}
					</h2>
					{cta && (
						<div className="show--md mt4">
							<Link
								{...cta}
								style={{
									backgroundColor:'#ffffff',
									color: foregroundColor,
								}}
								className="button--primary"
								aria-label="Duotone Bumper"
							/>
						</div>
					)}
				</div>

				<div className="show--md col c2--md" />

				<div className="col c4--md">
					<div className="rich-text line-break">
						<BlockContent
							blocks={description}
							serializers={richText}
						/>
					</div>

					{cta && (
						<div className="hide--md mt3">
							<Link
								{...cta}
								style={{
									backgroundColor: '#ffffff',
									color: foregroundColor,
								}}
								className="button--primary"
								aria-label="Duotone"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	</div>
);



