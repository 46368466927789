import React from 'react';
import richText from '../serializers/richText';
import BlockContent from '@sanity/block-content-to-react';
import Helmet from 'react-helmet';

function Dosage(props) {
  if(!props.data) return ;

return props.data.length>0 && (<table>
	<tr>
		<th>Poids</th>
		<th>Âge</th>
		<th>Dose</th>
	</tr>
	{ props.data.map(list => (
		<tr>
			<td>{list.weight}</td>
			<td>{list.age}</td>
			<td>{list.dose}</td>
		</tr>
	))}
</table>);

}
export default ({
	headerImage,
	title,
	medicineFor,
	medicineDetails,
	reliefFromList=[],
	ingredientsHeaderText,
	ingredients=[],
	ingredientsFooterText,
	ingredientsFooterInfo,
	cleanMedicineHeading,
	otherDetail,
	otherProductDetail=[],
	certifications=[],
	otherText,
	drugInfo=[]
}) => {

return (

	<div className="grid-container contained canadian-landing-page">
		<Helmet
			bodyAttributes={{
				class: 'canadian-landing-page-body'
			}}
			htmlAttributes={{
				lang: 'fr'
			}}
		/>
		<a aria-label="Site Map" href="https://www.genexa.com/sitemap.xml" class="full-hide">Sitemap</a> {/* Sitemap link accessibility */}
		<div className="page-contaner" >
			<header className="header bg--white">
				<div className="header__height df fdr jcb aic">
					<div className="fg0">
						<img
							alt="Genexa"
							className="db logo-with-tag"
							src="/images/genexa_canada_logo.png"
						/>
					</div>
				</div>
			</header>

			<div className="section1">
				{title && <div className=" sans--sm-md--md mb1 mt1">
					<BlockContent
						blocks={title}
						serializers={richText}/>
				</div>}

				{headerImage &&
					<div className="tc"><img alt={headerImage.alt} src={headerImage.url} /></div>
				}



				{medicineFor && <p className="button--pill--primary no-hover btn-tag">{medicineFor}</p>}


				{medicineDetails && <div className="mt1 mb1"><BlockContent
					blocks={medicineDetails}
					serializers={richText}/></div>
				}

				{reliefFromList && (
					<div className="">
						{reliefFromList.map(list => (
							<p className="button--pill--primary no-hover btn-tag">{list.reliefFrom}</p>
						))}
					</div>
				)}

				{ingredientsHeaderText && <BlockContent
					blocks={ingredientsHeaderText}
					serializers={richText}/>
				}
				{ingredients &&
					<>
						<div className="devider mt1 mb1"></div>
						<div className="ingredient-container">
							{ingredients && (
								<div className="tgs">
									{ingredients.map(list => (
										<p className="button--pill--secondary no-hover btn-tag-outline">{list.ingredient}</p>
									))}
								</div>

							)}
							{/*{ingredientsFooterInfo && <div className="border-content"><BlockContent*/}
							{/*	blocks={ingredientsFooterInfo}*/}
							{/*	serializers={richText}/></div>*/}
							{/*}*/}
						</div>
						<div className="mb1">
							{ingredientsFooterText && <BlockContent
								blocks={ingredientsFooterText}
								serializers={richText}/>
							}
						</div>
						<hr/>
					</>
				}

				{cleanMedicineHeading &&<p className="button--pill--primary no-hover btn-tag" ><strong>{cleanMedicineHeading}</strong></p>}

				{/*{otherDetail && <><div className="mt2"><BlockContent*/}
				{/*	blocks={otherDetail}*/}
				{/*	serializers={richText}/></div>*/}
				{/*	</>*/}
				{/*}*/}

				{otherProductDetail && <div className="mt2">
					{otherProductDetail.map(list => (
						<>
							<BlockContent
								blocks={list.details}
								serializers={richText}/>
							<hr/>
						</>
					))}
				</div>}

				{certifications && <><div className="flex__wrapper certi-img jcb mt3 mb3">
					{certifications.map(certification => (
						<div>
							<img alt={certification.certificationImage.alt}  src={`${certification.certificationImage.url}?w=90&auto=format`} />
						</div>
					))}
				</div><hr/></>}

				{otherText && <BlockContent
					blocks={otherText}
					serializers={richText}/>
				}
			</div>
			<div className="section2">
				{drugInfo && (
					<div className="mb1 druginfocontainer">
						{drugInfo.map(list => (
							<>
								{list.facts && <BlockContent
									blocks={list.facts}
									serializers={richText}
								/>}
								{list.dosageTable && <Dosage data={list.dosageTable}/>}
								{list.bottomBorderWidth && <hr style={{ border:`${list.bottomBorderWidth} solid #000000`}}/>}
							</>
						))}
					</div>
				)}
			</div>

		</div>
	</div>
);
}

