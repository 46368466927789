import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';

export default ({title, callouts = []}) => (
	<div className="bg--gray-bg">
		<div className="grid-container contained pt5 pb5 pt12--lg pb12--lg">
			<div className="rich-text--hero tc serif--xl serif--xxl--lg serif--xxxl--xl" style={{color:'#000000'}}>
				<BlockContent blocks={title} serializers={richText} />
			</div>
		</div>
		<div className="of--hidden">
			<div className="row gutter--none">
				{callouts.map(callout => (
					<div key={callout._key} className="col c6--md">
						<div className="pr">
							<Link
								{...callout.cta}
								className="db image size--9x5 x link--opacity" aria-label="Callout Collection">
								<picture>
									<source
										srcSet={`${callout.image.url}?w=900&auto=format&q=75`}
										media="(min-width: 1000px)"
									/>
									<source
										srcSet={`${callout.image.url}?w=600&auto=format&q=75`}
										media="(min-width: 600px)"
									/>
									<img
										alt={callout.image.alt}
										src={`${callout.image.url}?w=400&auto=format`}
									/>
								</picture>
							</Link>
							<div className="pa fill df fdc jcc aic pen">
								<Link
									{...callout.cta}
									className="pea button--primary"
									aria-label="Callout Collection"
									style={{
										backgroundColor: callout.ctaBackgroundColor,
										'--borderColor': callout.ctaBackgroundColor,
										color: callout.ctaColor,
									}}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	</div>
);
