import React, {useState} from 'react';
import classNames from 'classnames';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import chunk from 'lodash/chunk';
import sortBy from 'lodash/sortBy';
import ProductTileRelated from './ProductTileRelated';
import {connectToStore} from './Provider';
import {encode} from '../utils/shopify-gid';
import Flickity from './Flickity';
import getWholesaleVariant from '../utils/getWholesaleVariant';

const TRUNCATED_SYMPTOM_LENGTH = 18;

const MOBILE_SLIDE_OPTIONS = {
	cellAlign: 'left',
	freeScroll: true,
	contain: true,
	prevNextButtons: false,
	pageDots: false,
	selectedAttraction: 0.1,
	friction: 0.8,
};

const DESKTOP_SLIDE_OPTIONS = {
	cellAlign: 'left',
	contain: true,
	wrapAround: true,
	prevNextButtons: false,
	pageDots: false,
	selectedAttraction: 0.1,
	friction: 0.8,
};

const MAX_PRODUCTS = 24;

const ProductQuiz = ({
						 title,
						 ageFilterSubtitle,
						 ageFilterTitle,
						 symptomFilterSubtitle,
						 symptomFilterTitle,
						 emptyState,
						 ages = [],
						 symptoms = [],
						 products = [],
						 isAddingItem,
						 addItemAndOpenCart,
						 customer,
						 ctaColor,
						 ctaBackgroundColor
					 }) => {
	const sortedSymptoms = sortBy(symptoms, 'title');
	const [carousel, setCarousel] = useState(null);
	const [showAllSymptoms, updateShowAllSymptoms] = useState(false);
	const [activeAgeFilters, setAgeFilters] = useState([ages[0]._id]);
	const [activeSymptomFilters, setSymptomFilters] = useState([]);

	const truncatedSymptomFilters = showAllSymptoms
		? sortedSymptoms
		: sortedSymptoms.slice(0, TRUNCATED_SYMPTOM_LENGTH);

	let filteredProducts = filter(products, product => {
		let ages = product.ages || [];
		let symptoms = product.symptoms || [];
		let isAgeMatch = false;
		let isSymptomMatch = false;

		if (activeAgeFilters.length) {
			for (let i = 0; i < activeAgeFilters.length; i++) {
				if (ages.indexOf(activeAgeFilters[i]) > -1) {
					isAgeMatch = true;
				}
			}
		} else {
			isAgeMatch = true;
		}

		if (activeSymptomFilters.length) {
			for (let i = 0; i < activeSymptomFilters.length; i++) {
				if (symptoms.indexOf(activeSymptomFilters[i]) > -1) {
					isSymptomMatch = true;
				}
			}
		} else {
			isSymptomMatch = true;
		}

		return isAgeMatch && isSymptomMatch;
	});

	filteredProducts = filteredProducts.slice(0, MAX_PRODUCTS);

	const onToggleSymptom = _id => {
		if (activeSymptomFilters.indexOf(_id) > -1) {
			setSymptomFilters(without(activeSymptomFilters, _id));
		} else {
			setSymptomFilters(uniq([...activeSymptomFilters, _id]));
		}
	};

	const onToggleAge = _id => {
		if (activeAgeFilters.indexOf(_id) > -1) {
			setAgeFilters(without(activeAgeFilters, _id));
		} else {
			setAgeFilters(uniq([...activeAgeFilters, _id]));
		}
	};

	const onClickPrevious = () => {
		if (!carousel) return;

		carousel.previous();
	};

	const onClickNext = () => {
		if (!carousel) return;

		carousel.next();
	};

	const ageSelection = (
		<div className="pr10--xl">
			<h3 className="tc fw--800 mb3 mb4--lg">{ageFilterSubtitle}</h3>
			<h4 className="product-quiz__title tc fw--800 sans--md sans--lg--lg mb4 mb6--lg">
				{ageFilterTitle}
			</h4>
			<div className="df fdc fdr--lg jcc aic">
				{ages.map((age, index) => (
					<div
						key={index}
						className="mb1 mr1--lg mb0--lg df fdr jcc aic">
						<button
							title={age.title}
							aria-label={age.title}
							onClick={() => onToggleAge(age._id)}
							className={classNames('db product-quiz__age', {
								active: activeAgeFilters.indexOf(age._id) > -1,
							})}>
							<img
								alt={age.title + ' dark image'}
								src={`${age.tileImage.url}?h=300&auto=format`}
								className={classNames(
									'product-quiz__age-icon db',
									{
										o0:
											activeAgeFilters.indexOf(age._id) >
											-1,
									},
								)}
							/>
							<img
								alt={age.title + ' light image'}
								src={`${age.tileImageInvert.url}?h=300&auto=format`}
								className={classNames(
									'product-quiz__age-icon db',
									{
										o0:
											activeAgeFilters.indexOf(
												age._id,
											) === -1,
									},
								)}
							/>
							<span className="pa bottom left right p1">
								{age.title}
							</span>
						</button>
					</div>
				))}
			</div>
		</div>
	);

	const symptomSelection = (
		<div className="pl4--md pl10--xl">
			<h3 className="tc fw--800 mb3 mb4--lg">{symptomFilterSubtitle}</h3>
			<h4 className="product-quiz__title tc fw--800 sans--md sans--lg--lg mb4 mb6--lg">
				{symptomFilterTitle}
			</h4>
			<div className="df fdr aic jcs fw">
				{truncatedSymptomFilters.map((symptom, index) => (
					<div key={index} className="mr1 mb1">
						<button
							title={symptom.title}
							aria-label={symptom.title}
							onClick={() => onToggleSymptom(symptom._id)}
							style={{
								'--backgroundColor': symptom.color,
							}}
							className={classNames('db button--pill--filter quiz-bubbles', {
								active:
									activeSymptomFilters.indexOf(symptom._id) >
									-1,
							})}>
							<span>{symptom.title}</span>
						</button>
					</div>
				))}
				{showAllSymptoms ? (
					<div className="mr1 mb1">
						<button
							title="Less"
							aria-label="Less"
							onClick={() => updateShowAllSymptoms(false)}
							className="db fw--800 link--opacity">
							Less
						</button>
					</div>
				) : (
					<button
						title="Show More"
						aria-label="Show More"
						onClick={() => updateShowAllSymptoms(true)}
						className="link--opacity db mr1 mb1">
						<img
							alt="Show More"
							className="db"
							src="/images/icon-symptom-more.svg"
						/>
						<span className="dn">Show More</span>
					</button>
				)}
			</div>
		</div>
	);

	const productTiles = filteredProducts.map(product => {
		const discountVariant = getWholesaleVariant(product.variants, customer);
		const cents = discountVariant.cents;
		const variantId = discountVariant.variantId;

		const onAdd = () => {
			const {subscription} = product;
			const customAttributes = [];

			if (subscription) {
				customAttributes.push({
					key: '_subscription_options',
					value: JSON.stringify(subscription),
				});
			}

			const encodedVariantId = encode('ProductVariant', variantId, {
				accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
			});

			return addItemAndOpenCart([
				{
					quantity: 1,
					variantId: encodedVariantId,
					customAttributes,
				},
			]);
		};

		return (
			<ProductTileRelated
				productId={product.productId}
				image={product.mainImageInverted}
				title={product.title}
				cents={cents}
				slug={product.slug}
				isAdding={isAddingItem}
				onAdd={onAdd}
				ctaColor={ctaColor}
				ctaBackgroundColor={ctaBackgroundColor}
			/>
		);
	});

	const desktopProductTileChunks = chunk(productTiles, 3);

	const mobileProductCarousel = (
		<Flickity
			key={filteredProducts.map(p => p._id).join('-')}
			className="product-carousel hide--lg"
			options={MOBILE_SLIDE_OPTIONS}>
			{productTiles.map((tile, index) => (
				<div className="product-carousel__item mr3" key={index}>
					{tile}
				</div>
			))}
		</Flickity>
	);

	const desktopProductCarousel = (
		<div className="row align--middle show--lg">
			<div className="col c1">
				{desktopProductTileChunks.length > 1 && (
					<button
						title="Product"
						aria-label="Product"
						onClick={onClickPrevious}
						className="db link--opacity mra">
						<img
							className="db flip"
							src="/images/icon-arrow-quiz.svg"
							alt="Arrow"
						/>
					</button>
				)}
			</div>
			<div className="col c10">
				<Flickity
					sliderRef={sliderRef => setCarousel(sliderRef)}
					key={filteredProducts.map(p => p._id).join('-')}
					options={DESKTOP_SLIDE_OPTIONS}>
					{desktopProductTileChunks.map((rowProducts, index) => (
						<div key={index} className="x mr3">
							<div className="row gutter--large--xl">
								{rowProducts.map((tile, index) => (
									<div key={index} className="col c4">
										{tile}
									</div>
								))}
							</div>
						</div>
					))}
				</Flickity>
			</div>
			<div className="col c1">
				{desktopProductTileChunks.length > 1 && (
					<button
						title="Product"
						aria-label="Product"
						onClick={onClickNext}
						className="db link--opacity mla">
						<img className="db" src="/images/icon-arrow-quiz.svg" 	alt="Arrow" />
					</button>
				)}
			</div>
		</div>
	);

	const noResults = filteredProducts.length === 0 && (
		<div>
			<p className="serif--lg serif--xxl--lg tc mt4 mb4">{emptyState}</p>
			<div className="df fdr jcc">
				<button
					title="Show Me Everything"
					aria-label="Show Me Everything"
					className="button--primary"
					onClick={() => {
						setAgeFilters([]);
						setSymptomFilters([]);
					}}>
					Show Me Everything
				</button>
			</div>
		</div>
	);

	return (
		<div
			id="product-quiz"
			className="bg--gray-bg pt6 pb6 pt12--lg pb12--lg pr of--hidden">
			<div className="grid-container contained">
				<div className="row align--center">
					<div className="col c8--md">
						<h2 className="serif--lg serif--xxl--lg tc mb12">
							{title}
						</h2>
					</div>
				</div>
				<div className="row align--center">
					<div className="col c10--xl">
						<div className="pr product-quiz__filter-container pb12--lg mb4 mb12--lg">
							<div className="row">
								<div className="col c6--lg">{ageSelection}</div>
								<div className="col hide--lg mt12 mb6">
									<hr />
								</div>
								<div className="col c6--lg">
									{symptomSelection}
								</div>
							</div>
						</div>
					</div>
				</div>

				{mobileProductCarousel}
				{desktopProductCarousel}
				{noResults}
			</div>

			<img
				className="pen db product-quiz__image--1m hide--lg"
				src="/images/quiz-mobile-1.png"
				alt="Quiz 1"
			/>
			<img
				className="pen db product-quiz__image--2m hide--lg"
				src="/images/quiz-mobile-2.png"
				alt="Quiz 2"
			/>
			<img
				className="pen db product-quiz__image--1d show--lg"
				src="/images/quiz-desktop-1.png"
				alt="Quiz 1"
			/>
			<img
				className="pen db product-quiz__image--2d show--lg"
				src="/images/quiz-desktop-2.png"
				alt="Quiz 2"
			/>
		</div>
	);
};

const mapStateToProps = ({isAddingItem, customer}) => ({
	isAddingItem,
	customer,
});

const mapStoreToProps = ({addItemAndOpenCart}) => ({
	addItemAndOpenCart,
});

export default connectToStore(mapStateToProps, mapStoreToProps)(ProductQuiz);
