import React, {useState, useEffect} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';
import Flickity from './Flickity';
import IconArrowTestimonial from './IconArrowTestimonial';
import QuoteOpen from './QuoteOpen';
import QuoteClose from './QuoteClose';
import {SwitchTransition, Transition} from 'react-transition-group';

export const TRANSITION_DURATION = 400;
export const TRANSITION_DELAY = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease, transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate3d(0, 10px, 0)',
	},
	entered: {
		opacity: 1,
		transform: 'translate3d(0, 0, 0)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate3d(0, -10px, 0)',
	},
	exited: {
		opacity: 0,
		transform: 'translate3d(0, -10px, 0)',
	},
};

const IMAGE_SLIDE_OPTIONS = {
	cellAlign: 'center',
	contain: true,
	pageDots: true,
	prevNextButtons: false,
	selectedAttraction: 0.1,
	friction: 0.8,
	draggable: false,
	//autoPlay: 5000,
};

export default ({
	backgroundColor = '#659092',
	foregroundColor = '#B8E8BA',
	slides = [],
}) => {
	let [imageSlider, setImageRef] = useState(null);
	let [currentIndex, setIndex] = useState(0);
	const activeSlide = slides[currentIndex];

	useEffect(() => {
		if (!imageSlider) return;

		imageSlider.on('change', () => {
			setIndex(imageSlider.selectedIndex);
		});
	}, [imageSlider]);

	useEffect(() => {
		if (!imageSlider) return;

		imageSlider.select(currentIndex);
	}, [currentIndex]);

	const onClickPrevious = () => {
		if (currentIndex > 0) {
			setIndex(currentIndex - 1);
		}
	};

	const onClickNext = () => {
		if (currentIndex < slides.length - 1) {
			setIndex(currentIndex + 1);
		}
	};

	return (
		<div className="pb2 pb0--lg of--hidden">
			<div style={{ backgroundColor }} >
				<div className="grid-container flush">
					<div className="row gutter--none testimonial-carousel__row">
						<div className="col c12 c6--lg">
							<div className="testimonial__carousel-container">
								<Flickity
									sliderRef={carousel =>
										setImageRef(carousel)
									}
									options={IMAGE_SLIDE_OPTIONS}
									className="testimonial__carousel">
									{slides.map(slide => (
										<div
											className="db image size--1x1 x"
											key={slide._key}>
											<picture>
												<source
													srcSet={`${slide.image.url}?w=1000&auto=format&q=100`}
													media="(min-width: 800px)"
												/>
												<img
													alt={slide.image.alt}
													src={`${slide.image.url}?w=680&auto=format&q=100`}
												/>
											</picture>
										</div>
									))}
								</Flickity>
							</div>
						</div>
						<div className="col c1 show--xl" />
						<div
							style={{
								color: foregroundColor,
							}}
							className="col c6--lg c4--xl pt6 pb6 pt4--md pb4--md pr">
							<SwitchTransition>
								<Transition
									key={currentIndex}
									mountOnEnter
									unmountOnExit
									timeout={{
										enter: 100,
										exit:
											TRANSITION_DURATION +
											TRANSITION_DELAY,
									}}>
									{status => (
										<div
											style={{
												...TRANSITION_STYLES.default,
												...TRANSITION_STYLES[status],
											}}>
											<QuoteOpen className="db testimonial-carousel__image--before hide--lg" />
											<QuoteClose className="db testimonial-carousel__image--after hide--lg" />
											<div className="grid-container contained flush--xl">
												<div className="testimonial__content testimonial__content--carousel pl3--lg pr3--lg pl0--xl pr0--xl">
													<h2 className="sans--lg sans--xxl--lg fw--800 mb3 mb4--lg">
														{activeSlide.title}
													</h2>
													<div className="pr">
														<QuoteOpen className="db testimonial__image--before show--lg" />
														<div className="rich-text line-break">
															<BlockContent
																blocks={
																	activeSlide.description
																}
																serializers={
																	activeSlide.richText
																}
															/>
														</div>
														{activeSlide.author && (
															<p className="mb1 sans--xs">
																{
																	activeSlide.author
																}
															</p>
														)}
														{/*<IconStarsTestimonial className="db mb1" />*/}
														<QuoteClose className="db testimonial__image--after show--lg" />
													</div>
													{activeSlide.cta && (
														<div className="mt2 mt4--lg">
															<Link
																{...activeSlide.cta}
																className="button--primary invert"
																style={{
																	backgroundColor:'#ffffff',
																	'--borderColor':'#ffffff',
																	color: foregroundColor,
																}}
																aria-label="Testimonial image"
															/>
														</div>
													)}
												</div>
											</div>
										</div>
									)}
								</Transition>
							</SwitchTransition>

							<div className="grid-container contained flush--xl">
								<div className="mt2 df fdr aic pl3--lg pr3--lg pl0--xl pr0--xl">
									<button
										title="Testimonial"
										aria-label="Testimonial"
										className="db p1 pl0"
										onClick={onClickPrevious}>
										<IconArrowTestimonial className="db flip" />
									</button>
									<p className="fw--800">
										{currentIndex + 1}/{slides.length}
									</p>
									<button
										title="Testimonial"
										aria-label="Testimonial"
										className="db p1"
										onClick={onClickNext}>
										<IconArrowTestimonial className="db" />
									</button>
								</div>
							</div>
						</div>
						<div className="col c1 show--xl" />
					</div>
				</div>
			</div>
		</div>
	);
};
