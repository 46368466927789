import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({
	lefttext,
	righttext,
	leftBackgroundColor,
	rightBackgroundColor,
	image,
}) => (
	<section className="made-clean-wrap">
		<div className="grid-container flush">
			<div className="row gutter--none">
				<div className="col col c6--xl content-made-clean pt8 pb8" style={{
					backgroundColor: leftBackgroundColor,
				}}>
					<div className="row pl5 pr5 pl0--xl pr0--xl  gutter--none">
						<div className="col col c4--xl show--xl"></div>
						<div className="col col c6--xl">
							<BlockContent
								blocks={lefttext}
								serializers={richText}
							/>
						</div>
						<div className="col col c2--xl show--xl"></div>
					</div>

				</div>
				<div className="col col c6--xl ingredients-free pt8 pb8" style={{
					backgroundColor: rightBackgroundColor,
				}}>
					<div className="row gutter--none  pl5 pr5 pl0--xl pr0--xl">
						<div className="col col c2--xl show--xl"></div>
						<div className="col col c8--xl">
							<BlockContent
								blocks={righttext}
								serializers={richText}
							/>
						</div>
						<div className="col col c2--xl show--xl"></div>
					</div>
				</div>

			</div>
				<div className="hand-img">
					<picture>
						<img
							alt={image.alt}
							src={`${image.url}?w=400&auto=format`}
						/>
					</picture>
				</div>
		</div>
	</section>
);
