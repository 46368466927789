import React, {Component} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';

class CertificationsGridListItem extends Component {
	constructor(props) {
		super();

		this.state = {
			isHovering: false,
		};

		this.descriptionRef = React.createRef();
	}

	componentDidMount = () => {
		document.addEventListener('mousemove', this.onMouseMove);
	};

	componentWillUnmount = () => {
		document.removeEventListener('mousemove', this.onMouseMove);
	};

	onMouseEnter = () => {
		this.setState({
			isHovering: true,
		});
	};

	onMouseLeave = () => {
		this.setState({
			isHovering: false,
		});
	};

	onMouseMove = event => {
		const description = this.descriptionRef.current;
		description.style.left = `${event.clientX + 20}px`;
		description.style.top = `${event.clientY + 10}px`;
	};

	render() {
		const {description, logo} = this.props;

		return (
			<div
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}>
				<img
					alt={logo.alt}
					src={`${logo.url}?w=680&auto=format`}
					className="db certifications-grid__certification"
				/>
				<div
					ref={this.descriptionRef}
					className={classNames(
						'certifications-grid__modal show--lg sans--sm tl p1 pl2 pr2',
						{
							visible: this.state.isHovering,
						},
					)}>
					<BlockContent blocks={description} serializers={richText} />
				</div>
			</div>
		);
	}
}

export default ({title, certifications = [], cta, ctaColor}) => (
	<div className="hero">
		<div className="of--hidden grid-container contained">
			<div className="row align--center mb10">
				<div className="col c10--lg">
					<div className="rich-text--hero tc serif--xl serif--xxl--lg serif--xxxl--xl">
						<BlockContent blocks={title} serializers={richText} />
					</div>
				</div>
			</div>

			<div className="ofx--scroll certifications-grid__list">
				<ul className="df fdr jcs jcc--lg aic">
					{certifications.map(cert => (
						<li key={cert._key}>
							<div className="pr6 pl3--lg pr3--lg pr4--xl pl4--xl">
								<CertificationsGridListItem {...cert} />
							</div>
						</li>
					))}
				</ul>
			</div>

			{cta && (
				<div className="db x tc mxa mt5 mt12--md">
					<Link
						{...cta}
						style={{
							backgroundColor: ctaColor,
							'--borderColor': ctaColor,
						}}
						className={classNames('button--primary', {
							invert: ctaColor,
						})}
						aria-label="Certification"
					/>
				</div>
			)}
		</div>
	</div>
);
