import '../styles/components/plyr.scss';
import React from 'react';

export default class Vimeo extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		return import(/* webpackChunkName: 'plyr' */ 'plyr')
			.then(({default: Plyr}) => {
				// Initialize player
				this.player = new Plyr(this.containerEl, this.props.options);

				// Give player to parent
				this.props.getPlayerRef(this.player);

				// Start player
				this.player.play();
			})
			.catch(error => {
				console.log(error);
			});
	}

	componentWillUnmount() {
		this.player && this.player.destroy();
	}

	render() {
		return (
			<div
				ref={el => (this.containerEl = el)}
				dangerouslySetInnerHTML={{__html: this.props.html}}
				className="plyr"
			/>
		);
	}
}

Vimeo.defaultProps = {
	getPlayerRef: () => {},
	html: '',
	options: {},
};
