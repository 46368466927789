import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';
import Reveal from './Reveal';

export default ({
	title,
	titleWidth = 'c10',
	subtitle,
	subtitleWidth = 'c10',
	description,
	cta,
	ctaColor,
	index,
}) =>
{ 
	console.log(title[0].children[0].text);
	return (
	<div className="hero">
		<Reveal>
			<div className="grid-container contained">
				<div className="row align--center">
					<div
						className={classNames('col', {
							'c10--lg': titleWidth === 'c10',
							'c8--lg': titleWidth === 'c8',
						})}>
						<div className="rich-text--hero tc serif--xl serif--xxl--lg serif--xxxl--xl reveal__slide reveal__delay--2">
							<BlockContent
								blocks={title}
								serializers={richText}
							/>
						</div>
					</div>
				</div>

				{subtitle && (
					<div className="row align--center mt4 mt8--lg reveal__slide reveal__delay--3">
						<div
							className={classNames('col', {
								'c10--lg': subtitleWidth === 'c10',
								'c8--lg': subtitleWidth === 'c8',
							})}>
							<div className={`rich-text--hero serif--lg ${title[0].children[0].text === "Where To Buy" ? 'wheretobuydesc' : 'serif--xxl--xl'}`}>
								<BlockContent
									blocks={subtitle}
									serializers={richText}
								/>
							</div>
						</div>
					</div>
				)}

				{description && (
					<div className="row align--center mt2 mt4--lg reveal__slide reveal__delay--3">
						<div className="col c6--lg">
							<div className="tc rich-text line-break">
								<BlockContent
									blocks={description}
									serializers={richText}
								/>
							</div>
						</div>
					</div>
				)}

				{cta && ctaColor && (
					<div className="db x tc mxa mt5 mt7--md reveal__slide reveal__delay--4">
						<Link
							{...cta}
							style={{
								backgroundColor: ctaColor,
								'--borderColor': ctaColor,
							}}
							className={classNames('button--primary', {
								invert: ctaColor,
							})}
							aria-label="Slide"
						/>
					</div>
				)}
			</div>
		</Reveal>
	</div>
);}
