import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({
	text,
	image,
}) => (

	<section className="big-medicine-wrap pt8">

		<div className="big-medicine">
			<picture>
				<source
					srcSet={`${image.url}?w=2000&auto=format&q=75`}
					media="(min-width: 1000px)"
				/>
				<source
					srcSet={`${image.url}?w=1000&auto=format&q=75`}
					media="(min-width: 600px)"
				/>
				<img
					alt={image.alt}
					src={`${image.url}?w=680&auto=format`}
				/>
			</picture>
			<div className="big-medicine-content grid-container flush--lg pt5 pb5 pt0--lg pb0--lg">
				<div className="row gutter--none align--center ">
					<div className="col c4--xxl c7--xl c10--lg pt8--xxl pb8--xxl big-medicine-list">
						<BlockContent
							blocks={text}
							serializers={richText}
						/>
					</div>
				</div>
			</div>
		</div>
	</section>

);
