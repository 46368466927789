import React from 'react';

export default props => (
	<svg
		{...props}
		width="49"
		height="34"
		viewBox="0 0 49 34"
		fill="none">
		<title>Quote Close</title>
		<path
			d="M35.8635 2.31645C38.3828 2.31645 40.832 2 43.3177 2C44.1557 2 44.9937 2 45.8317 2C46.6416 2 46.3064 3.63179 46.3064 4.21516C46.3064 6.09957 46.6228 7.94608 46.6228 9.75306C46.6228 12.7871 47.2709 16.5022 46.4646 19.4048C45.8387 21.6583 45.1588 24.9827 43.4583 26.6832C41.6112 28.5303 39.2555 28.8094 37.2875 30.34C36.3109 31.0996 35.4418 31.1246 34.4219 31.5882C33.5634 31.9784 29.4612 32.1863 28.9719 31.43C27.7907 29.6045 30.1674 27.0932 30.1674 25.3471C30.1674 24.4322 29.7508 22.333 30.5541 21.6903C30.6428 21.6194 32.4794 22.2529 32.8572 22.2529C34.8894 22.2529 36.4964 21.9255 36.4964 19.7213C36.4964 18.437 36.8128 16.8941 36.8128 15.4492C36.8128 14.0706 34.7729 14.6581 33.7186 14.6581C32.225 14.6581 30.872 14.3416 29.3938 14.3416C26.8443 14.3416 27.6357 11.8758 27.6357 9.91129C27.6357 8.06532 27.6357 6.21935 27.6357 4.37339C27.6357 1.90695 30.1653 2.6329 32.1364 2.6329C33.0912 2.6329 36.4964 3.11897 36.4964 2"
			stroke="currentColor"
			strokeWidth="2.5"
			strokeLinecap="round"
			strokeLinejoin="round"/>
		<path
			d="M10.344 2.31645C12.8633 2.31645 15.3125 2 17.7981 2C18.6362 2 19.4742 2 20.3122 2C21.1221 2 20.7869 3.63179 20.7869 4.21516C20.7869 6.09957 21.1033 7.94608 21.1033 9.75306C21.1033 12.7871 21.7514 16.5022 20.9451 19.4048C20.3191 21.6583 19.6393 24.9827 17.9388 26.6832C16.0917 28.5303 13.7359 28.8094 11.768 30.34C10.7913 31.0996 9.92225 31.1246 8.90234 31.5882C8.04385 31.9784 3.94171 32.1863 3.45234 31.43C2.27116 29.6045 4.64783 27.0932 4.64783 25.3471C4.64783 24.4322 4.23125 22.333 5.0346 21.6903C5.12323 21.6194 6.95991 22.2529 7.33766 22.2529C9.36982 22.2529 10.9769 21.9255 10.9769 19.7213C10.9769 18.437 11.2933 16.8941 11.2933 15.4492C11.2933 14.0706 9.25341 14.6581 8.19911 14.6581C6.70547 14.6581 5.35243 14.3416 3.87428 14.3416C1.32473 14.3416 2.11621 11.8758 2.11621 9.91129C2.11621 8.06532 2.11621 6.21935 2.11621 4.37339C2.11621 1.90695 4.64574 2.6329 6.61686 2.6329C7.57167 2.6329 10.9769 3.11897 10.9769 2"
			stroke="currentColor"
			strokeWidth="2.5"
			strokeLinecap="round"
			strokeLinejoin="round"/>
	</svg>
);