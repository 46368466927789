import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({columns}) => (
	<div className="grid-container contained mt12--lg mt4 mb12--lg">
		<div className="row">
			{columns.map((column, i) => (
				<React.Fragment key={column._key}>
					<div className="col c1 show--lg" />
					<div className="col c6--md c4--lg mb4">
						<div className="rich-text line-break">
							<BlockContent
								blocks={column.richText}
								serializers={richText}
							/>
						</div>
					</div>
					<div className="col c1 show--lg" />
				</React.Fragment>
			))}
		</div>
	</div>
);
