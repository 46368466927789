import React, {useState} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';
import Radio from './Radio';
import {Transition} from 'react-transition-group';
import isTouchEnabled from '../utils/isTouchEnabled';

const TRANSITION_DURATION = 200;
export const TRANSITION_DELAY = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease, transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate3d(0, 10px, 0)',
	},
	entered: {
		opacity: 1,
		transform: 'translate3d(0, 0, 0)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate3d(0, -10px, 0)',
	},
	exited: {
		opacity: 0,
		transform: 'translate3d(0, -10px, 0)',
	},
};

const INGREDIENT_LAYOUT_STYLES = {
	One: [
		{
			rotate: '-26deg',
			top: '50%',
			left: '0%',
		},
		{
			rotate: '30deg',
			top: '100%',
			left: '25%',
		},
		{
			rotate: '-26deg',
			top: '0%',
			left: '50%',
		},
		{
			rotate: '-30deg',
			top: '100%',
			left: '90%',
		},
		{
			rotate: '-30deg',
			top: '100%',
			left: '90%',
			hidden: true,
		},
	],
	Two: [
		{
			rotate: '-20deg',
			top: '100%%',
			left: '0%',
		},
		{
			rotate: '0',
			top: '10%',
			left: '50%',
		},
		{
			rotate: '20deg',
			top: '-10%',
			left: '75%',
		},
		{
			rotate: '30deg',
			top: '100%',
			left: '90%',
		},
		{
			rotate: '-30deg',
			top: '100%',
			left: '100%',
			hidden: true,
		},
	],
	Three: [
		{
			rotate: '14deg',
			top: '20%',
			left: '0%',
		},
		{
			rotate: '-30deg',
			top: '100%',
			left: '20%',
		},
		{
			rotate: '26deg',
			top: '50%',
			left: '50%',
		},
		{
			rotate: '20deg',
			top: '100%',
			left: '80%',
		},
		{
			rotate: '-10deg',
			top: '0%',
			left: '90%',
		},
	],
	Four: [
		{
			rotate: '14deg',
			top: '20%',
			left: '0%',
		},
		{
			rotate: '-30deg',
			top: '100%',
			left: '20%',
		},
		{
			rotate: '26deg',
			top: '14%',
			left: '50%',
		},
		{
			rotate: '20deg',
			top: '100%',
			left: '80%',
		},
		{
			rotate: '-10deg',
			top: '0%',
			left: '90%',
		},
	],
};

export default ({title, cta, ingredientSets}) => {
	const isTouchDevice = isTouchEnabled();
	const [activeSetIndex, setActiveSetIndex] = useState(0);
	const activeSet = ingredientSets[activeSetIndex];
	const [activeIngredient, setActiveIngredient] = useState(null);

	const tabs = ingredientSets.map(({title, description}, index) => (
		<div className="pl1 pr1 pl0--lg pr0--lg mb2--lg">
			<div className="df fdc fdr--lg tc tl--lg">
				<Radio
					className="db mxa mb1"
					onClick={() => setActiveSetIndex(index)}
					active={activeSetIndex === index}
				/>
				<div
					className={classNames('ingredient-overview__tab pl2--lg', {
						active: activeSetIndex === index,
					})}>
					<button
						title={title}
						aria-label={title}
						onClick={() => setActiveSetIndex(index)}
						name={title}
						style={{color: "#000", backgroundColor:"#fff"}}
						className="fw--800">
						{title}
					</button>
					<div
						onClick={() => setActiveSetIndex(index)}
						className="show--lg rich-text line-break">
						<BlockContent
							blocks={description}
							serializers={richText}
						/>
					</div>
				</div>
			</div>
		</div>
	));

	const ingredients = activeSet.ingredients.map(
		({_key, title, color = '#000', image, imageLayout}) => {
			const imagePositions = INGREDIENT_LAYOUT_STYLES[imageLayout];
			const isActive = _key === activeIngredient && image && imageLayout;

			const onToggle = () => {
				if (isActive) {
					setActiveIngredient(null);
				} else {
					setActiveIngredient(_key);
				}
			};

			const onMouseEnter = !isTouchDevice
				? () => setActiveIngredient(_key)
				: null;
			const onMouseLeave = !isTouchDevice
				? () => setActiveIngredient(null)
				: null;

			const trigger = (
				<button
					title={title}
					aria-label={title}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onClick={isTouchDevice && onToggle}
					className={classNames({
						'no-hover': !image || !imageLayout,
						'button--pill--secondary': isActive || color =='#000000',
						'button--pill--primary': !isActive && color!='#000000',
					})}
					style={{
						color: isActive ? color : null,
						backgroundColor: !isActive && color!='#000000' ? color : null,
						'--borderColor': !isActive  && color!='#000000' ? color : null,
					}}>
					{title}
				</button>
			);

			let ingredientImages;

			if (image && imageLayout) {
				ingredientImages = imagePositions.map((position, index) => (
					<Transition
						key={index}
						in={isActive}
						mountOnEnter
						unmountOnExit
						timeout={TRANSITION_DURATION}>
						{status => (
							<img
								style={{
									...TRANSITION_STYLES.default,
									opacity: TRANSITION_STYLES[status].opacity,
									transform: `${TRANSITION_STYLES[status].transform} rotate(${imagePositions[index].rotate})`,
									top: imagePositions[index].top,
									left: imagePositions[index].left,
									display: imagePositions[index].hidden
										? 'none'
										: 'block',
								}}
								className="db ingredient__dot"
								src={`${image.url}?w=300&auto=format`}
								alt={image.alt}
							/>
						)}
					</Transition>
				));
			}

			return (
				<li className="ingredient-overview__pill mb1 mr1" key={_key}>
					{trigger}
					{ingredientImages}
				</li>
			);
		},
	);

	return (
		<div className="mt12 mb12">
			<div className="grid-container contained">
				<div className="pr">
					<div className="row align--center">
						<div className="col c10--lg">
							{title?.trim() && (<h2 className="sans--md sans--sm--lg fw--800 mb4 tc tl--lg">
								{title}
							</h2>)}
						</div>


						<div className="col c4--lg mb4 mb0--lg">
							<div className="df fdr fdc--lg jcc">{tabs}</div>
						</div>

						<div className="show--lg col c2--lg" />

						<div className="col c4--lg mb4 mb0--lg">
							<ul>{ingredients}</ul>
						</div>
					</div>

					<div className="hide--lg rich-text line-break tc">
						<BlockContent
							blocks={activeSet.description}
							serializers={richText}
						/>
					</div>

					{cta && (
						<div className="tc mt4 mt10--lg">
							<Link {...cta} className={'button--primary button--primary-yellow'} aria-label="Ingredient" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
