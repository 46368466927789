import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';
import imageAspectRatioStyles from '../utils/imageAspectRatioStyles';
import onClickHash from '../utils/onClickHash';

const HeroCarouselImageProductSlide = ({
                                           _key,
                                           slideBackground,
                                           title,
                                           description,
                                           cta,
                                           ctaColor,
                                           ctaBackgroundColor,
                                           extaInfo,
                                           extraInfoBackgroundColor,
                                           familyImage1,
                                           familyImage2,
                                           familyImage3,
                                           familyImage1Mobile,
                                           familyImage2Mobile,
                                           familyImage3Mobile,
                                           product1,
                                           product2,
                                           product3,
                                           product1mobile,
                                           product2mobile,
                                           product3mobile,
                                           product1cta,
                                           product2cta,
                                           product3cta,
                                       }) => (
    <div key={_key} className="home-hero pr x pt3 pt0--lg" style={{backgroundColor: slideBackground}}>
        <div className="x y push-up df fdc jcb jcc--lg">
            <div className="row gutter--none align--center aic slide-product-container">
                <div className="col c1 show--lg" />
                <div className="col c10--sm  c4--lg slider-left-container">
                    <div className="rich-text--hero tc serif--lg serif--xl--sm serif--xxl--lg reveal__slide reveal__delay--2 slider-title-block">
                        <BlockContent
                            blocks={title}
                            serializers={richText}
                        />
                    </div>

                    {description && (
                        <div className="tc rich-text line-break mt2 reveal__slide reveal__delay--3 ">
                            <BlockContent
                                blocks={description}
                                serializers={richText}
                            />
                        </div>
                    )}

                    {cta && (
                        <div className="mt3 tc reveal__slide reveal__delay--4">
                            <Link
                                {...cta}
                                onClick={event =>
                                    onClickHash(event, cta.url)
                                }
                                className="button--primary invert"
                                aria-label="Slide"
                                style={{
                                    backgroundColor: ctaBackgroundColor,
                                    '--borderColor': ctaBackgroundColor,
                                    color: ctaColor,
                                }}
                               
                            />
                        </div>
                    )}
                </div>
                <div className="col c1 show--lg" />
                <div className="col c12--sm  c6--lg y">
                    <div className="slider-right-container y">
                        <div className="product-image-container">
                            <div className="product-image-wrap">
                                <picture>
                                    <source
                                        srcSet={`${familyImage1.url}?w=600&auto=format&q=75`}
                                        media="(min-width: 1000px)"
                                    />
                                    <img
                                        alt={familyImage1Mobile.alt}
                                        src={`${familyImage1Mobile.url}?w=400&auto=format&q=75`}
                                    />
                                </picture>
                            </div>
                            <div className="product-image-wrap">
                                <picture>
                                    <source
                                        srcSet={`${familyImage2.url}?w=600&auto=format&q=75`}
                                        media="(min-width: 1000px)"
                                    />
                                    <img
                                        alt={familyImage2Mobile.alt}
                                        src={`${familyImage2Mobile.url}?w=400&auto=format&q=75`}
                                    />
                                </picture>
                            </div>
                            <div className="product-image-wrap">
                                <picture>
                                    <source
                                        srcSet={`${familyImage3.url}?w=600&auto=format&q=75`}
                                        media="(min-width: 1000px)"
                                    />
                                    <img
                                        alt={familyImage3Mobile.alt}
                                        src={`${familyImage3Mobile.url}?w=400&auto=format&q=75`}
                                    />
                                </picture>
                            </div>

                        </div>
                        <div class="row align--center x gutter--none slider-info-text  hide--lg" style={{backgroundColor: extraInfoBackgroundColor}}>
                            <div class="col c12 tc">
                                <BlockContent
                                    blocks={extaInfo}
                                    serializers={richText}
                                />
                            </div>
                        </div>
                        <div className="product-image-container">
                            <div className="product-image-wrap-bottom">
                                <Link url={`/products/${product1cta.slug}`} className="mb2" aria-label={product1mobile.alt}>

                                    <picture>

                                        <source
                                            srcSet={`${product1.url}?w=600&auto=format&q=75`}
                                            media="(min-width: 1000px)"
                                        />
                                        <img
                                            alt={product1mobile.alt}
                                            src={`${product1mobile.url}?w=400&auto=format&q=75`}
                                        />
                                    </picture>
                                </Link>
                            </div>
                            <div className="product-image-wrap-bottom">
                                <Link url={`/products/${product2cta.slug}`} className="mb2" aria-label={product2mobile.alt}>
                                    <picture>
                                        <source
                                            srcSet={`${product2.url}?w=600&auto=format&q=75`}
                                            media="(min-width: 1000px)"
                                        />
                                        <img
                                            alt={product2mobile.alt}
                                            src={`${product2mobile.url}?w=400&auto=format&q=75`}
                                        />
                                    </picture>
                                </Link>
                            </div>

                            <div className="product-image-wrap-bottom">
                                <Link url={`/products/${product3cta.slug}`} className="mb2" aria-label={product3mobile.alt}>
                                    <picture>
                                        <source
                                            srcSet={`${product3.url}?w=600&auto=format&q=75`}
                                            media="(min-width: 1000px)"
                                        />
                                        <img
                                            alt={product3mobile.alt}
                                            src={`${product3mobile.url}?w=400&auto=format&q=75`}
                                        />
                                    </picture>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align--center x gutter--none slider-info-text  show--lg" style={{backgroundColor: extraInfoBackgroundColor, color: '#000000'}}>
                <div class="col c12 tc">
                    <BlockContent
                        blocks={extaInfo}
                        serializers={richText}
                    />
                </div>
            </div>
        </div>

    </div>
);

export default HeroCarouselImageProductSlide;
