import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';

export default ({
	title,
	subtitle,
	cta,
	ctaColor,
	ctaBackgroundColor

}) =>{
   const hasCta=cta instanceof Array ?cta.length>0 :cta

   return (
		<section className="mt6--lg mb6--lg mt3 mb3">
			<div className="grid-container contained">
				<div className="row align--center">
					<div className="col c8--xl">
						<h2 className="serif--lg serif--xxl--lg tc igaf-heading">
							<BlockContent
								blocks={title}
								serializers={richText}
							/>
						</h2>
					</div>
					<div className="col c8--md c5--xl">
						<div className="subheading igaf-subheading sans--md--lg mt0--lg mb0--lg mb2 mt1">
							<BlockContent
								blocks={subtitle}
								serializers={richText}
							/>
						</div>

					</div>
				</div>

				{hasCta && (
					<div className="db x tc mxa">
						<Link
							{...cta}
							style={{
								backgroundColor: ctaBackgroundColor,
								color:ctaColor
							}}
							className={classNames('button--primary mobile-view-btn', {
								invert: ctaBackgroundColor,
							})}
							aria-label="Igaf"
						/>
					</div>
				)}
			</div>
		</section>
	);
}
