import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';
import classNames from 'classnames';

export default ({
	leftimage,
	rightimage,
	leftimagelink,
	rightimagelink,
	heading,
	subheading,
	cta,
	ctaColor,
	ctaBackgroundColor,
	leftcta,
	leftctaColor,
	leftctaBackgroundColor,
	rightcta,
	rightctaColor,
	rightctaBackgroundColor,
}) => (
	<section className="imagebyimage-container">
		<div className="grid-container flush">
			{(heading || subheading || cta) && (
					<div className="row align--center pt5--lg pb5--lg pt3 pb3 gutter--none">
						<div  className="col c10 c8--xl">
							{heading && (<div className="tc serif--lg serif--xxl--lg">
									<BlockContent
										blocks={heading}
										serializers={richText}
									/>
								</div>
							)}
							{subheading && (
								<div className="dyk-subheading tc fw--800 sans--md sans--lg--lg mb2--lg mt1--lg mt1 mb2">
									<BlockContent
										blocks={subheading}
										serializers={richText}
									/>
								</div>
							)}
							{cta && (
								<div className="db x tc mxa">
									<Link
										{...cta}
										style={{
											backgroundColor: ctaBackgroundColor,
											color:ctaColor
										}}
										className={classNames('button--primary mobile-view-btn', {
											invert: ctaBackgroundColor,
										})}
										aria-label="image"
									/>
								</div>
							)}

						</div>
					</div>
				)}
			<div className="row gutter--none align--middle">
				<div className="col c12 c6--lg pr lh--0">
					{leftimagelink ? (<Link {...leftimagelink}>
					<picture>
						<source
							srcSet={`${leftimage.url}?w=1000&auto=format&q=100`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${leftimage.url}?w=600&auto=format&q=100`}
							media="(min-width: 600px)"
						/>
						<img
							alt={leftimage.alt}
							src={`${leftimage.url}?w=400&auto=format`}
						/>
					</picture>
					</Link>):(
						<picture>
							<source
								srcSet={`${leftimage.url}?w=1000&auto=format&q=100`}
								media="(min-width: 1000px)"
							/>
							<source
								srcSet={`${leftimage.url}?w=600&auto=format&q=100`}
								media="(min-width: 600px)"
							/>
							<img
								alt={leftimage.alt}
								src={`${leftimage.url}?w=400&auto=format`}
							/>
						</picture>
					)}

					{leftcta && (
						<div className="db x tc mxa btn-wrap-imagebyimage">
							<Link
								{...leftcta}
								style={{
									backgroundColor: leftctaBackgroundColor,
									color:leftctaColor
								}}
								className={classNames('button--primary', {
									invert: leftctaBackgroundColor,
								})}
								aria-label="image"
							/>
						</div>
					)}
				</div>
				<div className="col c12 c6--lg pr">
					{rightimagelink ? (<Link {...rightimagelink}>
							<picture >
								<source
									srcSet={`${rightimage.url}?w=1000&auto=format&q=100`}
									media="(min-width: 1000px)"
								/>
								<source
									srcSet={`${rightimage.url}?w=600&auto=format&q=100`}
									media="(min-width: 600px)"
								/>
								<img
									alt={rightimage.alt}
									src={`${rightimage.url}?w=400&auto=format`}
									className="mt-1"
								/>
							</picture>
						</Link>):(
							<picture >
								<source
									srcSet={`${rightimage.url}?w=1000&auto=format&q=100`}
									media="(min-width: 1000px)"
								/>
								<source
									srcSet={`${rightimage.url}?w=600&auto=format&q=100`}
									media="(min-width: 600px)"
								/>
								<img
									alt={rightimage.alt}
									src={`${rightimage.url}?w=400&auto=format`}
									className="mt-1"
								/>
							</picture>
					)}
					{rightcta && (
						<div className="db x tc mxa btn-wrap-imagebyimage">
							<Link
								{...rightcta}
								style={{
									backgroundColor: rightctaBackgroundColor,
									color:rightctaColor
								}}
								className={classNames('button--primary', {
									invert: rightctaBackgroundColor,
								})}
								aria-label="image"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	</section>
);
