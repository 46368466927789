import React, {Component} from 'react';
import classNames from 'classnames';
import Link from './Link';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import min from 'lodash/min';
import max from 'lodash/max';
import {SwitchTransition, Transition} from 'react-transition-group';

export const TRANSITION_DURATION = 400;
export const TRANSITION_DELAY = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease, transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate3d(0, 10px, 0)',
	},
	entered: {
		opacity: 1,
		transform: 'translate3d(0, 0, 0)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate3d(0, -10px, 0)',
	},
	exited: {
		opacity: 0,
		transform: 'translate3d(0, -10px, 0)',
	},
};

const INITIAL_STATE = {
	activeSlideIndex: 0,
	isDragging: false,
	position: 0.35,
};

export default class IngredientComparisonCarousel extends Component {
	constructor() {
		super();
		this.state = {...INITIAL_STATE};

		// init scroll to hash feature
		this.scrollToHashId()

	}

	// Scroll to URL Hash Parameter
	scrollToHashId = () => {
		const removeHash = this.removeHash

		const hash = typeof window !== 'undefined'
			? window.location.hash.substring(1)
			: false;

		if (hash && hash.length) {
			setTimeout(
				window.requestAnimationFrame(function () {
					const el = document.getElementById(hash)
					el.scrollIntoView()
					removeHash()
				}),
				0
			)
		}
	}

	// After scroll - Clean the URL parameter
	removeHash = () => {
		const loc = typeof window !== 'undefined'
			? window.location
			: false;

		const hist = typeof window !== 'undefined'
			? window.history
			: false;

		if (hist && 'pushState' in hist) {
			hist.replaceState('', document.title, loc.pathname + loc.search)
		} else {
			const scrollV = document.body.scrollTop
			const scrollH = document.body.scrollLeft
			loc.hash = ''
			document.body.scrollTop = scrollV
			document.body.scrollLeft = scrollH
		}
	}

	componentDidMount = () => {
		global.addEventListener('mouseup', this.onMouseUp);
		global.addEventListener('touchend', this.onMouseUp);


	};

	componentWillUnmount = () => {
		global.removeEventListener('mouseup', this.onMouseUp);
		global.removeEventListener('touchend', this.onMouseUp);
		global.removeEventListener('mousemove', this.onMouseMove);
		global.removeEventListener('touchmove', this.onTouchMove);
	};

	onSetSlideIndex = activeSlideIndex => {
		this.setState({activeSlideIndex});
	};

	onClickPrevious = () => {
		const {slides = []} = this.props;

		if (this.state.activeSlideIndex > 0) {
			this.setState({
				activeSlideIndex: this.state.activeSlideIndex - 1,
			});
		} else {
			this.setState({
				activeSlideIndex: slides.length - 1,
			});
		}
	};

	onClickNext = () => {
		const {slides = []} = this.props;

		if (this.state.activeSlideIndex < slides.length - 1) {
			this.setState({
				activeSlideIndex: this.state.activeSlideIndex + 1,
			});
		} else {
			this.setState({
				activeSlideIndex: 0,
			});
		}
	};

	onMouseUp = () => {
		this.setState({
			isDragging: false,
			position: 0.35,
		});

		global.removeEventListener('mousemove', this.onMouseMove);
		global.removeEventListener('touchmove', this.onTouchMove);
	};

	onMouseDown = () => {
		this.setState({
			isDragging: true,
		});

		global.addEventListener('mousemove', this.onMouseMove);
		global.addEventListener('touchmove', this.onTouchMove);
	};

	onMouseMove = event => {
		if (!this.slideRef) return;

		const containerPosition = this.slideRef.getBoundingClientRect();
		const left = event.clientX - containerPosition.left;
		const position = left / containerPosition.width;

		this.setState({
			position: max([min([position, 1]), 0]),
		});
	};

	onTouchMove = event => {
		if (!this.slideRef) return;

		const containerPosition = this.slideRef.getBoundingClientRect();
		const left = event.touches[0].clientX - containerPosition.left;
		const position = left / containerPosition.width;

		this.setState({
			position: max([min([position, 1]), 0]),
		});
	};

	render() {
		const {title, description, cta,	ctaColor, ctaBackgroundColor, slides = []} = this.props;
		const {activeSlideIndex, isDragging, position} = this.state;
		const activeSlide = slides[activeSlideIndex];

		const {
			otherBrandIngredients = [],
			genexaIngredients = [],
			otherBrandDescription = [],
			genexaDescription = [],
			genexaProduct,
		} = activeSlide;

		const slideButtons = slides.map((slide, index) => (
			<button
				title={slide.title}
				aria-label={slide.title}
				key={slide._key}
				onClick={() => this.onSetSlideIndex(index)}
				style={{backgroundColor: slide.color,color:'#000000'}}
				className={classNames(
					'link--underline fw--800 ml1 mr1 ml3--lg mr3--lg slidebutton',
					{
						active: index === activeSlideIndex,
					},
				)}>
				{slide.title}
			</button>
		));

		const otherBrandsIngredients = (
			<React.Fragment>
				<p className="fw--800 sans--lg italic mb4">Other Brands</p>
				{otherBrandIngredients.map(ingredient => (
					<p className="fw--800 italic mb1" key={ingredient._key}>
						<span
							className={classNames('dib ws--nowrap', {
								'bg--black color--white pl1 pr1':
								ingredient.active,
							})}>
							{ingredient.title}
						</span>
					</p>
				))}
			</React.Fragment>
		);

		const genexaIngredientsList = (
			<React.Fragment>
				<img
					alt="Genexa"
					className="db mb4"
					src="/images/logo-ingredient-carousel.svg"
				/>
				{genexaIngredients.map(ingredient => (
					<p className="mb1" key={ingredient._key}>
						<button
							style={{
								color:'#000000',
								backgroundColor:activeSlide.color,
							}}
							className={classNames('ws--nowrap', {
								'button--pill--primary no-hover':
								ingredient.active,
								'button--pill--primary no-hover inactive': !ingredient.active,
							})}>
							{ingredient.title}
						</button>
					</p>
				))}
			</React.Fragment>
		);

		const genexaProductInfo = genexaProduct && (
			<div className="show--lg mt4">
				<p className="fw--800 mb1">{genexaProduct.title}</p>
				<div
					data-bv-show="inline_rating"
					data-bv-product-id={genexaProduct.productId}
				/>
			</div>
		);

		const slideCompareImage = (
			<div
				onMouseDown={this.onMouseDown}
				onTouchStart={this.onMouseDown}
				className={classNames('ingredient-compare__slide-col', {
					active: isDragging,
				})}>
				<div ref={el => (this.slideRef = el)} className="pr">
					<div
						style={{
							clipPath: `polygon(0 0, ${position * 100}% 0, ${
								position * 100
							}% 100%, 0 100%)`,
						}}
						className="ingredient-compare__rect">
						<SwitchTransition>
							<Transition
								key={activeSlideIndex}
								mountOnEnter
								unmountOnExit
								timeout={{
									enter: 100,
									exit:
										TRANSITION_DURATION + TRANSITION_DELAY,
								}}>
								{status => (
									<div
										style={{
											...TRANSITION_STYLES.default,
											...TRANSITION_STYLES[status],
											transitionDelay: '200ms',
										}}>
										<div className="ml4--lg mr4--lg">
											<div className="image ingredient-compare__slide-container">
												<picture>
													<source
														srcSet={`${activeSlide.otherBrandBottleDesktop.url}?w=2200&auto=format&q=75`}
														media="(min-width: 1200px)"
													/>
													<source
														srcSet={`${activeSlide.otherBrandBottleDesktop.url}?w=1500&auto=format&q=75`}
														media="(min-width: 1000px)"
													/>
													<source
														srcSet={`${activeSlide.otherBrandBottleMobile.url}?w=1000&auto=format&q=75`}
														media="(min-width: 600px)"
													/>
													<img
														alt={
															activeSlide
																.otherBrandBottleMobile
																.alt
														}
														src={`${activeSlide.otherBrandBottleMobile.url}?w=600&auto=format&q=75`}
													/>
												</picture>
											</div>
										</div>
									</div>
								)}
							</Transition>
						</SwitchTransition>

						<SwitchTransition>
							<Transition
								key={activeSlideIndex}
								mountOnEnter
								unmountOnExit
								timeout={{
									enter: 100,
									exit:
										TRANSITION_DURATION + TRANSITION_DELAY,
								}}>
								{status => (
									<React.Fragment>
										<div
											className="pa bottom right pr8 show--lg"
											style={{
												...TRANSITION_STYLES.default,
												...TRANSITION_STYLES[status],
												transitionDelay: '400ms',
											}}>
											<div className="ingredient-compare__description">
												<BlockContent
													blocks={
														otherBrandDescription
													}
													serializers={richText}
												/>
											</div>
										</div>
										<div
											className="pa top left pt2 show--lg"
											style={{
												...TRANSITION_STYLES.default,
												...TRANSITION_STYLES[status],
											}}>
											{otherBrandsIngredients}
										</div>
									</React.Fragment>
								)}
							</Transition>
						</SwitchTransition>
					</div>
					<div className="pa fill z1">
						<div
							style={{
								clipPath: `polygon(${
									position * 100
								}% 0, 100% 0, 100% 100%, ${
									position * 100
								}% 100%)`,
							}}
							className="ingredient-compare__rect">
							<SwitchTransition>
								<Transition
									key={activeSlideIndex}
									mountOnEnter
									unmountOnExit
									timeout={{
										enter: 100,
										exit:
											TRANSITION_DURATION +
											TRANSITION_DELAY,
									}}>
									{status => (
										<div
											style={{
												...TRANSITION_STYLES.default,
												...TRANSITION_STYLES[status],
												transitionDelay: '200ms',
											}}>
											<div className="ml4--lg mr4--lg">
												<div className="image ingredient-compare__slide-container">
													<picture>
														<source
															srcSet={`${activeSlide.genexaBottleDesktop.url}?w=2200&auto=format&q=75`}
															media="(min-width: 1200px)"
														/>
														<source
															srcSet={`${activeSlide.genexaBottleDesktop.url}?w=1500&auto=format&q=75`}
															media="(min-width: 1000px)"
														/>
														<source
															srcSet={`${activeSlide.genexaBottleMobile.url}?w=1000&auto=format&q=75`}
															media="(min-width: 600px)"
														/>
														<img
															alt={
																activeSlide
																	.genexaBottleMobile
																	.alt
															}
															src={`${activeSlide.genexaBottleMobile.url}?w=600&auto=format&q=75`}
														/>
													</picture>
												</div>
											</div>
										</div>
									)}
								</Transition>
							</SwitchTransition>

							<SwitchTransition>
								<Transition
									key={activeSlideIndex}
									mountOnEnter
									unmountOnExit
									timeout={{
										enter: 100,
										exit:
											TRANSITION_DURATION +
											TRANSITION_DELAY,
									}}>
									{status => (
										<React.Fragment>
											<div
												className="pa top left pl8 show--lg"
												style={{
													...TRANSITION_STYLES.default,
													...TRANSITION_STYLES[
														status
														],
													transitionDelay: '400ms',
												}}>
												<div className="ingredient-compare__description">
													<BlockContent
														blocks={
															genexaDescription
														}
														serializers={richText}
													/>
												</div>
											</div>
											<div
												className="pa top right pt2 show--lg"
												style={{
													...TRANSITION_STYLES.default,
													...TRANSITION_STYLES[
														status
														],
													transitionDelay: '400ms',
												}}>
												{genexaIngredientsList}
												{genexaProductInfo}
											</div>
										</React.Fragment>
									)}
								</Transition>
							</SwitchTransition>
						</div>
					</div>

					<SwitchTransition>
						<Transition
							key={activeSlideIndex}
							mountOnEnter
							unmountOnExit
							timeout={{
								enter: 100,
								exit: TRANSITION_DURATION + TRANSITION_DELAY,
							}}>
							{status => (
								<div
									style={{
										...TRANSITION_STYLES.default,
										...TRANSITION_STYLES[status],
										transitionDelay: '200ms',
									}}
									className="pa fill z1">
									<div
										className="ingredient-compare__slide-line z1"
										style={{left: `${position * 100}%`}}
									/>
									<button
										title="Compare"
										aria-label="Compare"
										className="ingredient-compare__slide-drag z1"
										style={{left: `${position * 100}%`}}>
										<img
											draggable="false"
											alt="Compare"
											className="db"
											src="/images/icon-drag-compare-carousel.svg"
										/>
									</button>
								</div>
							)}
						</Transition>
					</SwitchTransition>
				</div>
			</div>
		);

		const carousel = (
			<div className="row mb4 mb12--xl df fdr">
				<div className="col c1 show--xl ingredient-compare__arrow-col fdc jcc">
					<button
						title="Arrow Compare"
						aria-label="Arrow Compare"
						name="Previous"
						onClick={this.onClickPrevious}
						className="db link--opacity">
						<img
							alt="Previous"
							className="db flip"
							src="/images/icon-arrow-compare-carousel.svg"
						/>
					</button>
				</div>

				<div className="col c10--xl">
					<div className="pr df fdr jcc">
						<div className="usn fg1">{slideCompareImage}</div>
					</div>
				</div>

				<div className="col c1 show--xl ingredient-compare__arrow-col fdc jcc">
					<button
						title="Arrow Compare"
						aria-label="Arrow Compare"
						name="Next"
						onClick={this.onClickNext}
						className="db link--opacity mla">
						<img
							alt="Next"
							className="db"
							src="/images/icon-arrow-compare-carousel.svg"
						/>
					</button>
				</div>
			</div>
		);

		return (
			<div className="grid-container contained mt6 mb6 mt12--lg mb12--lg">
				<div className="row align--center mb8--lg z2">
					<div className="col c8--md c6--xl">
						<h2 className="serif--lg serif--xxl--lg tc mb5--lg" id="whats-in-your-medicine">
							{title}
						</h2>
					</div>
					<div className="col c12">
						<div className="grid-container-reset">
							<div className="ofx--scroll pt3 pb3 pt0--lg pb0--lg">
								<div className="grid-container df jcs jcc--lg fdr ws--nowrap">
									{slideButtons}
									<div className="pr2 hide--lg" />
								</div>
							</div>
						</div>
					</div>
				</div>

				{carousel}

				<div className="mb3 tc rich-text line-break hide--md">
					<BlockContent blocks={description} serializers={richText} />
				</div>

				{cta && (
					<div className="db x tc mxa mt2 mt4--md">
						<Link {...cta}
							  className="button--primary"
							  style={{
								  backgroundColor: ctaBackgroundColor,
								  '--borderColor': ctaBackgroundColor,
								  color: ctaColor,
							  }}
							  aria-label="Ingredient"
						/>
					</div>
				)}
			</div>
		);
	}
}
