import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';

export default ({title, description, ctaList = [], index}) => (
	<div className="grid-container contained mt6 mb6 mt12--md mb12--md">
		<div className="row align--center">
			<div className="col c10--lg">
				<div className="rich-text--hero tc serif--xl serif--xxl--lg serif--xxxl--xl">
					<BlockContent blocks={title} serializers={richText} />
				</div>
			</div>
		</div>

		{description && (
			<div className="row align--center mt2 mt4--lg">
				<div className="col c6--lg">
					<div className="tc rich-text line-break">
						<BlockContent
							blocks={description}
							serializers={richText}
						/>
					</div>
				</div>
			</div>
		)}

		{!!ctaList.length && (
			<div className="row align--center mt4 mt6--md">
				{ctaList.map(cta => (
					<div key={cta._key} className="col c6 c4--md c3--lg c2--xl">
						<Link {...cta} className="db x button--primary" aria-label="List" />
					</div>
				))}
			</div>
		)}
	</div>
);
