import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({
	text,
	image,
}) => (
	<section>
		<div className="grid-container contained">
			<div className="row  align--center">
				<div  className="col c9--xl">
					<div className="tc fw--800 sans--md mb4 mb6--lg pr2 pl2">
						<BlockContent
							blocks={text}
							serializers={richText}
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="">
			<div className="">
				<picture>
					<source
						srcSet={`${image.url}?w=2000&auto=format&q=75`}
						media="(min-width: 1000px)"
					/>
					<source
						srcSet={`${image.url}?w=1000&auto=format&q=75`}
						media="(min-width: 600px)"
					/>
					<img
						className="db"
						alt={image.alt}
						src={`${image.url}?w=680&auto=format`}
					/>
				</picture>
			</div>
		</div>

	</section>
);
