import React from 'react';
import Seo from '../components/Seo';
import ComponentList from '../components/ComponentList';
import Link from '../components/Link';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({pageContext, location}) => {
    const {seo = {}, productCategory = {}, productBrand = {}, components = [], hideFromSearchEngine} = pageContext;

    const metaTitle = seo.metaTitle || `Genexa Vs ${pageContext.title} - Genexa`;
    const openGraphTitle =
        seo.openGraphTitle || `Genexa Vs  ${pageContext.title} - Genexa`;
    const twitterTitle = seo.twitterTitle || `Genexa Vs  ${pageContext.title} - Genexa`;
    const medicinelink = {title: "Medicine", url: "/compare"};
    const categorylink = {title: productCategory.title, url: "/compare/c/" + productCategory.slug};
    const brandlink = {title: productBrand.title, url: "/compare/brand/" + productBrand.slug};

    return (
        <React.Fragment>
            <Seo
                metaTitle={metaTitle}
                metaDescription={seo.metaDescription}
                metaKeywords={seo.metaKeywords}
                openGraphTitle={openGraphTitle}
                openGraphDescription={seo.openGraphDescription}
                openGraphImage={seo.openGraphImage}
                twitterTitle={twitterTitle}
                twitterDescription={seo.twitterDescription}
                twitterImage={seo.twitterImage}
                pathname={location.pathname}
                noIndexNoFollow={hideFromSearchEngine}
            />
            <div class="compare-container">
                <div className='comparehead'>
                    <h1>Genexa</h1>
                    <h3>VS</h3>
                    <h3>{pageContext.title}</h3>
                </div>

                <div class="bread-crumbs">
                    <ul>
                        <li><Link {...medicinelink} aria-label="Medicine Link"/></li>
                        <li><Link {...categorylink} aria-label="Category Link"/></li>
                        <li><Link {...brandlink} aria-label="Brand Link"/></li>
                    </ul>
                </div>
                <div>
                    {pageContext.description && (
                        <div class="paragraph-content">
                            <div className="tc rich-text line-break">
                                <BlockContent
                                    blocks={pageContext.description}
                                    serializers={richText}
                                />
                            </div>
                        </div>
                    )}

                    {productBrand.warning && (
                        <div class="warning">
                            <div class="warning-inner">
                                <div className="tc rich-text line-break">
                                    <BlockContent
                                        blocks={productBrand.warning}
                                        serializers={richText}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <ComponentList components={components}/>
            </div>
        </React.Fragment>
    );
};
