import React, {useState, Fragment} from 'react';
import SanityMuxPlayer from 'sanity-mux-player';
import Flickity from './Flickity';
import {Transition} from 'react-transition-group';
import Reveal from './Reveal';
import HeroCarouselImageSlide from './HeroCarouselImageSlide';
import HeroCarouselVideoSlide from './HeroCarouselVideoSlide';
import HeroCarouselImageProductSlide from './HeroCarouselImageProductSlide';

const SLIDE_OPTIONS = {
	cellAlign: 'center',
	contain: false,
	wrapAround: true,
	prevNextButtons: false,
	selectedAttraction: 0.1,
	friction: 0.8,
	autoPlay: 5000,
};

export const MODAL_TRANSITION_DURATION = 800;

const UNDERLAY_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	entered: {
		opacity: 1,
		transform: 'translate(-50%, -50%)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	exited: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
};

export default ({slides = []}) => {
	const [isOpen, setOpen] = useState(false);
	const [activeVideo, setActiveVideo] = useState(null);

	const modalContents = (
		<div className="x">
			<SanityMuxPlayer
				assetDocument={activeVideo ? activeVideo.asset : null}
				className="mb1 x"
				autoload
				showControls
				data-keepplaying
			/>
			<div className="df jce">
				<button 
					title="Close" 
					aria-label="Close"
					className="color--white"
					onClick={() => {
						setOpen(false);
					}}>
					Close
				</button>
			</div>
		</div>
	);

	const videoModal = (
		<Transition
			in={isOpen}
			appear
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<React.Fragment>
					<div
						onClick={() => setOpen(false)}
						className="home-hero-video-modal__underlay pf fill"
						style={{
							...UNDERLAY_TRANSITION_STYLES.default,
							...UNDERLAY_TRANSITION_STYLES[status],
						}}
					/>
					<div
						className="x pa home-hero-video-modal__content"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							...MODAL_TRANSITION_STYLES[status],
						}}>
						{modalContents}
					</div>
				</React.Fragment>
			)}
		</Transition>
	);

	return (
		<Fragment>
			<div className="home-hero__carousel-container">
				<Reveal>
					<Flickity options={SLIDE_OPTIONS}>
						<Slides
							slides={slides}
							isModalOpen={isOpen}
							setActiveVideo={activeVideo => {
								setActiveVideo(activeVideo);
								setOpen(true);
							}}
						/>
					</Flickity>
				</Reveal>
			</div>
			{videoModal}
		</Fragment>
	);
};

const Slides = ({slides = [], setActiveVideo, isModalOpen}) => (
	<Fragment>
		{slides.map((slide, index) => {
			switch (slide._type) {
				case 'heroHome':
					return (
						<HeroCarouselImageSlide
							index={index}
							key={slide._key}
							{...slide}
						/>
					);
				case 'productSlide':
					return (
						<HeroCarouselImageProductSlide
							index={index}
							key={slide._key}
							{...slide}
						/>
					);
				case 'videoSlide':
					return (
						<HeroCarouselVideoSlide
							index={index}
							setActiveVideo={setActiveVideo}
							isModalOpen={isModalOpen}
							key={slide._key}
							{...slide}
						/>
					);
			}
		})}
	</Fragment>
);
