import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({
	text,
	backgroundColor,
}) => (
	<section className="big-medicine-wrap pt8" 	style={{
		backgroundColor: backgroundColor,
	}}>

		<div className="grid-container contained">
			<div className="row align--center">
				<div  className="col c8--xl">
					<div className="tc fw--800 sans--md sans--lg--lg">
						<BlockContent
							blocks={text}
							serializers={richText}
						/>
					</div>
				</div>
			</div>

		</div>
	</section>
);
